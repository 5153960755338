import React from "react";
import $ from "jquery";

import Button from "@material-ui/core/Button";
import { FormControl, MenuItem, Select } from "@material-ui/core";

import {
  ID_NO_ASIGNADO,
  ID_ASIGNADO,
  ID_ACEPTADO,
  ID_INICIADO,
} from "../../utils/Helpers";
import {
  ID_EN_PROGRESO,
  ID_EXITOSO,
  ID_FALLIDO,
  ID_RECHAZADO,
  ID_CANCELADO,
} from "../../utils/Helpers";
import Utils from "../../utils/Utils";
import url from "../../../config";

import CustomExpandMore from "../../components/custom/CustomExpandMore";
import IngresarTemplate from "../../components/home/IngresarTemplate";

class EditarEstado extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ingresarEvidencia: false,
    };
  }

  handleChangeCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  modificarEstado = async () => {
    let usuario = JSON.parse(sessionStorage.getItem("user"));
    if (
      this.props.opcionEditarEstado === ID_CANCELADO &&
      this.props.failedReason === ""
    ) {
      Utils.handleAlert(
        "Faltan campos",
        "Debe seleccionar un estado para poder editar las tareas de este grupo, vuelva a intentarlo",
        "Ok"
      );
    } else if (this.props.opcionEditarEstado === "null") {
      Utils.handleAlert(
        "Faltan campos",
        "Debe seleccionar un estado para poder editar las tareas de este grupo, vuelva a intentarlo",
        "Ok"
      );
    } else {
      if (this.props.vista === "mapa") {
        try {
          this.props.handleCargando(true);
          let modificar;
          if (this.props.opcionEditarEstado === ID_CANCELADO) {
            modificar = {
              key: this.props.data._id,
              idstatus_taskgroup: this.props.opcionEditarEstado,
              modifier_user: {
                name: usuario.name,
                email: usuario.email,
              },
              failed_reason: this.props.failedReason,
            };
          } else {
            modificar = {
              key: this.props.data._id,
              idstatus_taskgroup: this.props.opcionEditarEstado,
              modifier_user: {
                name: usuario.name,
                email: usuario.email,
              },
            };
          }

          let res = await url.api.put(
            "/Task-Groups/tasks_group_tasks_status/",
            modificar
          );
          if (res.status === 200) {
            $("#modal").modal("hide");
            if (
              this.props.tareasRayo !== null &&
              this.props.rayo !== null &&
              typeof this.props.tareasRayo !== "undefined" &&
              typeof this.props.rayo !== "undefined"
            ) {
              this.props.getTasksRayo(this.props.rayo);
            }
            await this.props.handleCargando(false);
            this.props.getTasksGroupById(this.props.data._id);
            Utils.handleAlert(
              "Grupo de tareas modificado",
              "Se ha actualizado el estado al grupo de tareas exitosamente",
              "Ok"
            );
          } else {
            await this.props.handleCargando(false);
            Utils.handleAlert(
              "Error " + res.status,
              "Ha ocurrido un error al actualizar el estado al grupo de tareas, vuelva a intentarlo",
              "Ok"
            );
          }
        } catch (error) {
          await this.props.handleCargando(false);
          Utils.handleAlert(
            "Error",
            "Ha ocurrido un error no controlado al actualizar el estado, vuelva a intentarlo",
            "Ok"
          );
        }
      } else {
        try {
          await this.props.handleCargando(true);

          if (this.state.ingresarEvidencia) {
            let data = {
              result: "",
              template_data: [],
              modifier_user: {
                name: usuario.name,
                email: usuario.email,
              },
            };

            let tempData = [];
            if (this.props.opcionEditarEstado === ID_EXITOSO) {
              data.result = "Exitoso";
              tempData = Utils.templateData(this.props.template.successful);
            } else if (this.props.opcionEditarEstado === ID_FALLIDO) {
              data.result = "Fallido";
              tempData = Utils.templateData(this.props.template.failed);
            }

            data.template_data = tempData;

            await url.api
              .post("/Tasks/tasks/" + this.props.data._id + "/templateData", data)
              .then((res) => {
                if (res.status === 200 || res.status === 201) {
                  $("#modal").modal("hide");
                  this.props.handleCargando(false);
                  Utils.handleAlert(
                    "Tarea modificada",
                    "Se ha actualizado el estado de la tarea exitosamente",
                    "Ok"
                  );
                } else {
                  this.props.handleCargando(false);
                  Utils.handleAlert(
                    "Error " + res.status,
                    "Ha ocurrido un error al actualizar el estado de la tarea, vuelva a intentarlo",
                    "Ok"
                  );
                }
              })
              .catch((error) => {
                console.log(error)
                this.props.handleCargando(false);
                Utils.handleAlert(
                  "Error al realizar la petición",
                  error.message,
                  "Ok"
                );
              });
          } else {
            let modificar;
            if (this.props.opcionEditarEstado === ID_CANCELADO) {
              modificar = {
                key: this.props.data._id,
                idstatus_task: this.props.opcionEditarEstado,
                modifier_user: {
                  name: usuario.name,
                  email: usuario.email,
                },
                failed_reason: this.props.failedReason,
              };
            } else {
              modificar = {
                key: this.props.data._id,
                idstatus_task: this.props.opcionEditarEstado,
                modifier_user: {
                  name: usuario.name,
                  email: usuario.email,
                },
              };
            }
            await url.api
              .put("/Tasks/task_status/", modificar)
              .then((res) => {
                if (res.status === 200) {
                  $("#modal").modal("hide");
                  this.props.handleCargando(false);
                  Utils.handleAlert(
                    "Tarea modificada",
                    "Se ha actualizado el estado de la tarea exitosamente",
                    "Ok"
                  );
                } else {
                  this.props.handleCargando(false);
                  Utils.handleAlert(
                    "Error " + res.status,
                    "Ha ocurrido un error al actualizar el estado de la tarea, vuelva a intentarlo",
                    "Ok"
                  );
                }
              })
              .catch((error) => {
                if (error.response) {
                  let title = "Error en acción " + error.response.status;
                  if (error.response.status === 412) {
                    title = "Precondición fallida";
                  } else if (error.response.status === 403) {
                    title = "Acción solicitada invalida";
                  }
                  this.props.handleCargando(false);
                  Utils.handleAlert(
                    title,
                    error.response.data.error.detail.error_message,
                    "Ok"
                  );
                } else {
                  this.props.handleCargando(false);
                  Utils.handleAlert(
                    "Error al realizar la petición",
                    error.message,
                    "Ok"
                  );
                }
              });
          }
        } catch (error) {
          this.props.handleCargando(false);
          Utils.handleAlert(
            "Error",
            "Ha ocurrido un error no controlado al actualizar el estado, vuelva a intentarlo",
            "Ok"
          );
        }
      }
    }
  };

  // modificarEstado = () => {
  //   if (this.state.ingresarEvidencia) {
  //     let data = {
  //       result: "",
  //       modifier_user: {},
  //       template_data: [],
  //     };

  //     let tempData = [];
  //     if (this.props.opcionEditarEstado === ID_EXITOSO) {
  //       data.result = "Exitoso";
  //       tempData = Utils.templateData(this.props.template.successful);
  //     } else if (this.props.opcionEditarEstado === ID_FALLIDO) {
  //       data.result = "Fallido";
  //       tempData = Utils.templateData(this.props.template.failed);
  //     }

  //     data.template_data = tempData;

  //   }
  // };

  render() {
    return (
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-white">
          <div className="modal-body">
            <h4 className="h4-modal text-center">Cambiar de estado</h4>
            <h4 className="text-roboto-regular color-mega-dark-grey text-center">
              {this.props.vista === "mapa"
                ? "ID Grupo de Tareas"
                : "ID de Tarea"}
            </h4>
            {this.props.vista === "mapa" ? (
              <input
                defaultValue={
                  this.props.data === null ? "" : this.props.data.code_taskgroup
                }
                disabled
                className="form-control input-id-grupo mb-3"
              />
            ) : (
              <input
                defaultValue={
                  this.props.data === null ? "" : this.props.data.code_task
                }
                disabled
                className="form-control input-id-grupo mb-3"
              />
            )}

            <label className="text-roboto-regular color-mega-dark-grey mb-1">
              Selecciona la opción
            </label>
            <FormControl variant="filled" className="w-100">
              <Select
                className="select-crear select-modal m-estados color-mega-dark-grey p-0"
                labelId="opcionEditarEstado"
                name="opcionEditarEstado"
                value={this.props.opcionEditarEstado}
                onChange={(e) => {
                  this.setState({ ingresarEvidencia: false });
                  this.props.handleChange(e);
                }}
                IconComponent={CustomExpandMore}
              >
                <MenuItem className="row m-0" value={ID_NO_ASIGNADO}>
                  <div className="col-3 d-flex align-items-center">
                    <div className="dot back-medium-grey"></div>
                  </div>
                  <div className="col-9 pl-0 d-flex align-items-center">
                    No asignado
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value={ID_ASIGNADO}>
                  <div className="col-3 d-flex align-items-center">
                    <div className="dot dot-asignado"></div>
                  </div>
                  <div className="col-9 pl-0 d-flex align-items-center">
                    Asignado
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value={ID_ACEPTADO}>
                  <div className="col-3 d-flex align-items-center">
                    <div className="dot dot-aceptado"></div>
                  </div>
                  <div className="col-9 pl-0 d-flex align-items-center">
                    Aceptado
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value={ID_INICIADO}>
                  <div className="col-3 d-flex align-items-center">
                    <div className="dot dot-iniciado"></div>
                  </div>
                  <div className="col-9 pl-0 d-flex align-items-center">
                    Iniciado
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value={ID_EN_PROGRESO}>
                  <div className="col-3 d-flex align-items-center">
                    <div className="dot dot-en-progreso"></div>
                  </div>
                  <div className="col-9 pl-0 d-flex align-items-center">
                    En Progreso
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value={ID_EXITOSO}>
                  <div className="col-3 d-flex align-items-center">
                    <div className="dot dot-exitoso"></div>
                  </div>
                  <div className="col-9 pl-0 d-flex align-items-center">
                    Exitoso
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value={ID_FALLIDO}>
                  <div className="col-3 d-flex align-items-center">
                    <div className="dot dot-haFallado"></div>
                  </div>
                  <div className="col-9 pl-0 d-flex align-items-center">
                    Fallido
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value={ID_RECHAZADO}>
                  <div className="col-3 d-flex align-items-center">
                    <div className="dot dot-rechazado"></div>
                  </div>
                  <div className="col-9 pl-0 d-flex align-items-center">
                    Rechazado
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value={ID_CANCELADO}>
                  <div className="col-3 d-flex align-items-center">
                    <div className="dot dot-cancelado"></div>
                  </div>
                  <div className="col-9 pl-0 d-flex align-items-center">
                    Cancelado
                  </div>
                </MenuItem>
              </Select>
            </FormControl>

            {this.props.opcionEditarEstado === ID_CANCELADO ? (
              <div>
                <label className="text-roboto-regular color-mega-dark-grey mb-1 mt-3">
                  Ingrese motivo
                </label>
                <input
                  placeholder="Motivo..."
                  className="form-control txt-modal"
                  name="failedReason"
                  value={this.props.failedReason}
                  onChange={this.props.handleChange}
                  maxLength={80}
                />
              </div>
            ) : (
              false
            )}

            {this.props.vista==="lista" && this.props.opcionEditarEstado === ID_EXITOSO ? (
              <IngresarTemplate
                ingresarEvidencia={this.state.ingresarEvidencia}
                handleChangeCheck={this.handleChangeCheck}
                template={this.props.template.successful}
                typeTemplate="successful"
                optionsDropdown={this.props.optionsDropdownSucces}
                handleChange={this.props.handleChangeEvidencia}
                handleChangeImg={this.props.handleChangeEvidenciaImg}
              />
            ) : this.props.vista==="lista" && this.props.opcionEditarEstado === ID_FALLIDO ? (
              <IngresarTemplate
                ingresarEvidencia={this.state.ingresarEvidencia}
                handleChangeCheck={this.handleChangeCheck}
                template={this.props.template.failed}
                typeTemplate="failed"
                optionsDropdown={this.props.optionsDropdownFailed}
                handleChange={this.props.handleChangeEvidencia}
                handleChangeImg={this.props.handleChangeEvidenciaImg}
              />
            ) : (
              false
            )}
          </div>
          <div className="modal-footer row m-0">
            <div className="col-6 d-flex justify-content-center">
              <Button
                type="button"
                variant="text"
                color="primary"
                onClick={() => $("#modal").modal("hide")}
              >
                Cancelar
              </Button>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <Button
                type="button"
                variant="contained"
                color="secondary"
                onClick={this.modificarEstado}
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditarEstado;
