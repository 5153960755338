import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
// import "./css/global.css";

// <============= B O O T S T R A P =============>
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import theme from "./assets/theme/CustumizedTheme";

// <================= R E D U X =================>
import { Provider } from "react-redux";
import { createStore, applyMiddleware, combineReducers } from "redux";
import ReduxThunk from "redux-thunk";
import rayosReducer from "../src/store/reducers/Rayos";
import grupoTareasReducer from "../src/store/reducers/GrupoTareas";
import clientesReducer from "../src/store/reducers/Clientes";
import comunasReducer from "../src/store/reducers/Comunas";
import templatesReducer from "../src/store/reducers/Templates";
import tipoActividadesReducer from "../src/store/reducers/Tipo_Actividades";
import tipoVehiculosReducer from "../src/store/reducers/Tipo_Vehiculos";
import seleccionReducer from "../src/store/reducers/SeleccionMapa";
import estadosReducer from "../src/store/reducers/Estados";
import webhooksReducer from "../src/store/reducers/Webhooks";
import proveedoresReducer from "../src/store/reducers/Proveedores";
import trackingReducer from "../src/store/reducers/Tracking";

// <================ V I S T A S  D I S P A T C H E R ================>
import Tracking from "./views/tracking/Tracking";
import Layout from "./views/dispatcher/layout/Layout.js";
import Login from "./views/auth/Login";
import VistaMapa from "./views/dispatcher/home/VistaMapa";
import VistaLista from "./views/dispatcher/home/VistaLista";
import Tarea from "./assets/components/tareas/Tarea";
import Testing from "./views/Testing";
import PerfilRayo from "./views/PerfilRayo";

import TrackingLink from "./view/tracking/TrackingLink";
import TipoActividadesEj from "./view/mantenedores/TipoActividades";

import Ciudades from "./views/mantenedores/Ciudades";
import Clientes from "./views/mantenedores/Clientes";
import Comunas from "./views/mantenedores/Comunas";
import Dispatchers from "./views/mantenedores/Dispatchers";
import Paises from "./views/mantenedores/Paises";
import Proveedores from "./views/mantenedores/Proveedores";
import Rayos from "./views/mantenedores/Rayos";
import Regiones from "./views/mantenedores/Regiones";
import Templates from "./views/mantenedores/Templates";
import TipoActividades from "./views/mantenedores/Tipo_Actividades";
import TipoVehiculos from "./views/mantenedores/Tipo_Vehiculos";
import Webhooks from "./views/mantenedores/Webhooks";
// import MapaTest from "./assets/components/mapas/MapaTest";

// <================ V I S T A S  C L I E N T E S ================>
import LayoutCustomers from "./views/customer/layout/Layout";
import VistaListaCustomers from "./views/customer/home/VistaLista";
import VistaCustomers from "./views/customer/home/VistaRecepcion";

import PrivateRouteCustomer from "./routes/PrivateRouteCustomer";
import PrivateRouteDispatcher from "./routes/PrivateRouteDispatcher";

// import { createTheme } from "@material-ui/core/styles";

// <=============== R E D U C E R ===============>
const rootReducer = combineReducers({
  rayos: rayosReducer,
  grupoTareas: grupoTareasReducer,
  clientes: clientesReducer,
  comunas: comunasReducer,
  templates: templatesReducer,
  tipoActividades: tipoActividadesReducer,
  tipoVehiculos: tipoVehiculosReducer,
  seleccion: seleccionReducer,
  estados: estadosReducer,
  webhooks: webhooksReducer,
  proveedores: proveedoresReducer,
  tracking: trackingReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      themeMaterial: theme,
    };
  }

  componentDidMount = async () => {
    // this.setState(
    //   {
    //     themeMaterial: {
    //       ...this.state.themeMaterial,
    //       palette: {
    //         ...this.state.themeMaterial.palette,
    //         primary: {
    //           light: "#262626",
    //           main: "#0d0d0d",
    //           dark: "#000000",
    //           contrastText: "#fff",
    //         },
    //         secondary: {
    //           light: "#ffff80",
    //           main: "#FFFF00",
    //           dark: "#e6e600",
    //           contrastText: "#000",
    //         },
    //         success: {
    //           light: "#ffffff",
    //           main: "#f2f3f3",
    //           dark: "#bec1c1",
    //           contrastText: "#000",
    //         },
    //       },
    //     },
    //   }
    // );

    // this.setState({
    //   themeMaterial: {
    //     ...this.state.themeMaterial,
    //     palette: {
    //       ...this.state.themeMaterial.palette,
    //       primary: {
    //         light: "#e8554a",
    //         main: "#DA291C",
    //         dark: "#b52217",
    //         contrastText: "#fff",
    //       },
    //       secondary: {
    //         light: "#ffd666",
    //         main: "#ffc72c",
    //         dark: "#ffbb00",
    //         contrastText: "#fff",
    //       },
    //       success: {
    //         light: "#ffffff",
    //         main: "#f2f3f3",
    //         dark: "#bec1c1",
    //         contrastText: "#000",
    //       },
    //     },
    //   },
    // });
  };

  render() {
    return (
      <MuiThemeProvider theme={this.state.themeMaterial}>
        <Provider store={store}>
          <Switch>
            <Route
              exact
              path="/tracking-link/:tipo/:id"
              component={TrackingLink}
            />
            <PrivateRouteDispatcher
              exact
              path="/tipo-de-actividades"
              component={(props) => (
                <Layout data={props} contenido={<TipoActividadesEj />} />
              )}
            />

            <Route exact path="/" component={Login} />
            <Route exact path="/Login" component={Login} />
            <Route exact path="/Tracking/:tipo/:id" component={Tracking} />
            {/* <Route exact path="/Testing" component={MapaTest} /> */}

            <Route exact path="/perfilRayo/:id" component={PerfilRayo} />

            <PrivateRouteDispatcher
              exact
              path="/Home/Lista"
              component={(props) => (
                <Layout data={props} contenido={<VistaLista data={props} />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/Home/Mapa"
              component={(props) => (
                <Layout data={props} contenido={<VistaMapa data={props} />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/Home/Tarea/:accion/:tipo"
              component={(props) => (
                <Layout data={props} contenido={<Tarea data={props} />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/Ciudades"
              component={(props) => (
                <Layout data={props} contenido={<Ciudades />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/Clientes"
              component={(props) => (
                <Layout data={props} contenido={<Clientes />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/Comunas"
              component={(props) => (
                <Layout data={props} contenido={<Comunas />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/Dispatchers"
              component={(props) => (
                <Layout data={props} contenido={<Dispatchers />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/Paises"
              component={(props) => (
                <Layout data={props} contenido={<Paises />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/Proveedores"
              component={(props) => (
                <Layout data={props} contenido={<Proveedores />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/Rayos"
              component={(props) => (
                <Layout data={props} contenido={<Rayos />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/Regiones"
              component={(props) => (
                <Layout data={props} contenido={<Regiones />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/Templates"
              component={(props) => (
                <Layout data={props} contenido={<Templates />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/Webhooks"
              component={(props) => (
                <Layout data={props} contenido={<Webhooks />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/TipoActividades"
              component={(props) => (
                <Layout data={props} contenido={<TipoActividades />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/TipoVehiculos"
              component={(props) => (
                <Layout data={props} contenido={<TipoVehiculos />} />
              )}
            />
            <PrivateRouteDispatcher
              exact
              path="/TestingList"
              component={(props) => (
                <Layout data={props} contenido={<Testing />} />
              )}
            />
            <PrivateRouteCustomer
              exact
              path="/tasks"
              component={(props) => (
                <LayoutCustomers
                  data={props}
                  contenido={<VistaListaCustomers />}
                />
              )}
            />
            <PrivateRouteCustomer
              exact
              path="/retiros-en-tienda"
              component={(props) => (
                <LayoutCustomers data={props} contenido={<VistaCustomers />} />
              )}
            />
            <PrivateRouteCustomer
              exact
              path="/Home/Tarea/:accion"
              component={(props) => (
                <LayoutCustomers
                  data={props}
                  contenido={<Tarea data={props} />}
                />
              )}
            />
          </Switch>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

let Root;

Root = (
  <BrowserRouter>
    <Index />
  </BrowserRouter>
);

ReactDOM.render(Root, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
