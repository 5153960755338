import React from "react";
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import "../../css/global.css";
import Utils from "../../assets/utils/Utils";
import CustomExpandMore from "../../assets/components/custom/CustomExpandMore";
import url from "../../config";
import * as actionEstados from "../../store/actions/Estados";
import * as actionWebhooks from "../../store/actions/Webhooks";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import IconoWebhookWhite from "../../assets/icons/icono-webhook-white.svg";
import IconoCerrar from "../../assets/icons/icono-cerrar-white.svg";
import IconoCerrarBlack from "../../assets/icons/icono-cerrar-red.svg";

import { MenuItem, Select, FormControl } from "@material-ui/core";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

import CreateIcon from "@material-ui/icons/Create";

const TableWebhooks = ({ tipo, webhooks, estados, search, openModal }) => {
  return (
    <TableContainer className="table-white">
      <Table aria-label="Webhooks Tasks">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={3}>
              <b>{tipo}</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {estados.map((estado, i) => (
            <TableRow hover tabIndex={-1} key={i}>
              <TableCell>
                {estado.name_status ? estado.name_status : estado.name_statu}
              </TableCell>
              <TableCell align="left">
                {webhooks === null
                  ? false
                  : webhooks
                      .filter((webhook) => {
                        let bandera = false;
                        if (
                          webhook.idcustomer_webhookTask
                            .toString()
                            .toLowerCase()
                            .includes(search.toLowerCase()) &&
                          webhook.idstatus_webhookTask
                            .toLowerCase()
                            .includes(estado._id.toLowerCase())
                        ) {
                          bandera = true;
                        }
                        return bandera;
                      })
                      .map((webhook, index) => (
                        <div className="w-100" key={index}>
                          {webhook.url_webhookTask}
                        </div>
                      ))}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  aria-label="update"
                  onClick={() => openModal(estado, tipo, webhooks)}
                >
                  <CreateIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableWebhooksGT = ({ tipo, webhooks, estados, search, openModal }) => {
  return (
    <TableContainer className="table-white mt-4 mb-5">
      <Table aria-label="Webhooks Task Groups">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={3}>
              <b>{tipo}</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {estados.map((estado, i) => (
            <TableRow hover tabIndex={-1} key={i}>
              <TableCell>
                {estado.name_status ? estado.name_status : estado.name_statu}
              </TableCell>
              <TableCell align="left">
                {webhooks === null
                  ? false
                  : webhooks
                      .filter((webhook) => {
                        let bandera = false;
                        if (
                          webhook.idcustomer_webhookTasksGroup
                            .toString()
                            .toLowerCase()
                            .includes(search.toLowerCase()) &&
                          webhook.idstatus_webhookTasksGroup
                            .toLowerCase()
                            .includes(estado._id.toLowerCase())
                        ) {
                          bandera = true;
                        }
                        return bandera;
                      })
                      .map((webhook, index) => {
                        return (
                          <div className="w-100 text-black" key={index}>
                            {webhook.url_webhookTasksGroup[0]}
                          </div>
                        );
                      })}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  aria-label="update"
                  onClick={() => openModal(estado, tipo, webhooks)}
                >
                  <CreateIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

class Webhooks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      custumers: [],
      custumerSelected: "",
      statusSelected: "",
      statusNameSelected: "",
      tipoSelected: "",
      url_ingresada: "",

      tipoWebhook: "",
      webhooks: [],
    };
  }

  componentDidMount = () => {
    this.getCustumers();
    this.getEstados();
    this.getWebhooks();
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  getEstados = async () => {
    try {
      const { dispatch } = this.props;
      const obtenerEstados = actionEstados.obtenerEstados();
      dispatch(obtenerEstados);
    } catch (error) {
      Utils.handleAlert(
        "Error no controlado",
        "No ha sido posible cargar la data de la página principal",
        "Ok"
      );
    }
  };

  getWebhooks = async () => {
    try {
      const { dispatch } = this.props;
      const obtenerWebhooksTareas = actionWebhooks.obtenerWebhooksTareas();
      const obtenerWebhooksGruposTareas = actionWebhooks.obtenerWebhooksGruposTareas();
      dispatch(obtenerWebhooksTareas);
      dispatch(obtenerWebhooksGruposTareas);
    } catch (error) {
      Utils.handleAlert(
        "Error no controlado",
        "No ha sido posible cargar la data de la página principal",
        "Ok"
      );
    }
  };

  getCustumers = async () => {
    try {
      let clientes = Utils.getCustomers();
      let data = await axios({
        method: "get",
        url: url.host + "/Customers/customers" + clientes,
      }).then(({ data }) => data);
      let custumerSelected = "";
      if (data.length !== 0) {
        custumerSelected = data[0]._id;
      }
      this.setState({ custumers: data, custumerSelected: custumerSelected });
    } catch (err) {
      Utils.handleAlert(
        "Obtener clientes",
        "No se han podido cargar los clientes",
        "Ok"
      );
    }
  };

  openModal = (estado, tipo, webhooks) => {
    let tipoNombre = "TasksGroup";
    if (tipo === "Tarea") {
      tipoNombre = "Task";
    }
    this.setState({
      tipoWebhook: tipoNombre,
      tipoSelected: tipo,
      statusSelected: estado._id,
      statusNameSelected: estado.name_status,
      webhooks: webhooks,
    });
    $("#administrarWebhooks").modal("show");
  };

  handleAlertDelete = (id) => {
    Swal.fire({
      title: "¿Eliminar URL?",
      text:
        "Si confirma, no podrá deshacer la acción y el webhook será eliminado de forma permanente",
      imageUrl:
        "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg",
      showCancelButton: true,
      customClass: {
        image: "imageSweetAlert",
        title: "titleSweetAlert",
        content: "textSweetAlert",
        confirmButton: "buttonSweetAlert",
        cancelButton: "btn-secondary",
      },
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        if (this.state.tipoSelected === "Tarea") {
          this.deleteWebhookTask(id);
        } else {
          this.deleteWebhookTasksGroup(id);
        }
      }
    });
  };

  deleteWebhookTask = async (id) => {
    try {
      let eliminar = { idWebhook: id };
      let res = await url.api.delete("/Webhooks/webhooks_task/", {
        data: eliminar,
        headers: { Authorization: "***" },
      });
      if (res.status === 200) {
        this.getWebhooks();
        $("#administrarWebhooks").modal("hide");
        Utils.handleAlert(
          "Webhook eliminado",
          "El webhook se ha eliminado exitosamente",
          "Ok"
        );
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al eliminar el webhook, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al eliminar el webhook, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  deleteWebhookTasksGroup = async (id) => {
    try {
      let eliminar = { idWebhook: id };
      let res = await url.api.delete("/Webhooks/webhooks_tasks_group/", {
        data: eliminar,
        headers: { Authorization: "***" },
      });
      if (res.status === 200) {
        this.getWebhooks();
        $("#administrarWebhooks").modal("hide");
        Utils.handleAlert(
          "Webhook eliminado",
          "El webhook se ha eliminado exitosamente",
          "Ok"
        );
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al eliminar el webhook, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al eliminar el webhook, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  createWebhook = async () => {
    try {
      if (this.state.tipoSelected === "Tarea") {
        let crearT = {
          idstatus_webhookTask: this.state.statusSelected,
          idcustomer_webhookTask: this.state.custumerSelected,
          url_webhookTask: [this.state.url_ingresada],
        };
        let res = await url.api.post("/Webhooks/webhooks_task/", crearT);
        if (res.status === 201 || res.status === 200) {
          this.getWebhooks();
          $("#administrarWebhooks").modal("hide");
          this.setState({
            url_ingresada: "",
          });
          Utils.handleAlert(
            "Webhook ingresado",
            "El webhook se ha ingresado exitosamente",
            "Ok"
          );
        } else {
          Utils.handleAlert(
            "Error " + res.status,
            "Ha ocurrido un error al ingresar el webhook, vuelva a intentarlo",
            "Ok"
          );
        }
      } else {
        let crearGT = {
          idstatus_webhookTasksGroup: this.state.statusSelected,
          idcustomer_webhookTasksGroup: this.state.custumerSelected,
          url_webhookTasksGroup: [this.state.url_ingresada],
        };
        let res = await url.api.post(
          "/Webhooks/webhooks_tasks_group/",
          crearGT
        );
        if (res.status === 201 || res.status === 200) {
          this.getWebhooks();
          $("#administrarWebhooks").modal("hide");
          this.setState({
            url_ingresada: "",
          });
          Utils.handleAlert(
            "Webhook ingresado",
            "El webhook se ha ingresado exitosamente",
            "Ok"
          );
        } else {
          Utils.handleAlert(
            "Error " + res.status,
            "Ha ocurrido un error al ingresar el webhook, vuelva a intentarlo",
            "Ok"
          );
        }
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al crear webhook, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  render() {
    const estados = this.props.estados.estados;
    const webhooksTareas = this.props.webhooks.webhooksTareas;
    const webhooksGruposTareas = this.props.webhooks.webhooksGruposTareas;

    const LinksIngresados = () => {
      return this.state.webhooks
        .filter((webhook) => {
          let bandera = false;
          if (
            webhook["idcustomer_webhook" + this.state.tipoWebhook]
              .toString()
              .toLowerCase()
              .includes(this.state.custumerSelected.toLowerCase()) &&
            webhook["idstatus_webhook" + this.state.tipoWebhook]
              .toLowerCase()
              .includes(this.state.statusSelected.toLowerCase())
          ) {
            bandera = true;
          }
          return bandera;
        })
        .map((webhook, index) => {
          return (
            <div
              className={index === 0 ? "row m-0 w-100" : "row m-0 w-100 mt-2"}
              key={index}
            >
              <div className="col-10 pl-0">
                <input
                  type="text"
                  name="comunas"
                  className="form-control light-disabled"
                  defaultValue={
                    webhook["url_webhook" + this.state.tipoWebhook][0]
                  }
                  disabled
                />
              </div>
              <div className="col-2 p-0 d-flex justify-content-end align-items-center">
                <div
                  className="square-template back-light-grey d-flex justify-content-center align-items-center cursor-pointer"
                  onClick={() => this.handleAlertDelete(webhook._id)}
                >
                  <img
                    src={IconoCerrarBlack}
                    height="25"
                    alt="icono"
                    className="mb-0"
                  />
                </div>
              </div>
            </div>
          );
        });
    };

    return (
      <Box pt={4}>
        <Container>
          <Typography variant="h1" gutterBottom>
            Webhooks
          </Typography>

          <FormControl
            variant="filled"
            style={{ width: "30ch" }}
            className="mb-4"
          >
            <Select
              className="select-crear select-modal"
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              name="custumerSelected"
              value={this.state.custumerSelected}
              onChange={(e) => this.handleChange(e)}
              IconComponent={CustomExpandMore}
            >
              {this.state.custumers.map((cliente, index) => (
                <MenuItem value={cliente._id} key={index}>
                  {cliente.name_customer}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {estados === null ? (
            false
          ) : (
            <div>
              <TableWebhooks
                tipo="Tarea"
                webhooks={webhooksTareas}
                estados={this.props.estados.estados}
                search={this.state.custumerSelected}
                openModal={this.openModal}
              />
              <TableWebhooksGT
                tipo="Grupo de Tarea"
                webhooks={webhooksGruposTareas}
                estados={this.props.estados.estados}
                search={this.state.custumerSelected}
                openModal={this.openModal}
              />
            </div>
          )}

          <div
            className="modal fade"
            id="administrarWebhooks"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="administrarWebhooks"
            data-backdrop="static"
            aria-hidden="true"
            scrollable="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <img src={IconoWebhookWhite} height="35" alt="Logo Rayo" />
                  <div
                    style={{ flexDirection: "column" }}
                    id="editarModalLabel"
                  >
                    <h4 className="modal-title title-text-white ml-3">
                      Administrar Webhooks
                    </h4>
                    <h6 className="text-white ml-3 text-roboto-regular text-capitalize">
                      {this.state.tipoSelected +
                        "s " +
                        this.state.statusNameSelected}
                    </h6>
                  </div>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => $("#administrarWebhooks").modal("hide")}
                    aria-label="Close"
                  >
                    <span aria-hidden="true" className="title-text-white">
                      <img src={IconoCerrar} height="37" alt="Logo Rayo" />
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={this.validarWebhook}>
                    <div className="form-row">
                      <div className="form-group col-12">
                        <label className="texto-form mb-1">Ingrese URL</label>
                        <label className="texto-form-red-light">*</label>
                        <div className="row m-0">
                          <div className="col-11 p-0">
                            <input
                              required
                              className="form-control"
                              placeholder="URL"
                              value={this.state.url_ingresada}
                              name="url_ingresada"
                              onChange={(e) => this.handleChange(e)}
                            />
                          </div>
                          <div className="col-1 pr-0">
                            <button
                              type="submit"
                              className="btn back-sky-blue br-4 w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer"
                            >
                              <img
                                src={IconoCerrar}
                                height="20"
                                className="rotar-icono-45"
                                alt="icon"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="w-100 row m-0 back-light-grey icon-table p-3">
                    <LinksIngresados />

                    {this.state.webhooks.filter((webhook) => {
                      let bandera = false;
                      if (
                        webhook["idcustomer_webhook" + this.state.tipoWebhook]
                          .toString()
                          .toLowerCase()
                          .includes(
                            this.state.custumerSelected.toLowerCase()
                          ) &&
                        webhook["idstatus_webhook" + this.state.tipoWebhook]
                          .toLowerCase()
                          .includes(this.state.statusSelected.toLowerCase())
                      ) {
                        bandera = true;
                      }
                      return bandera;
                    }).length === 0 ? (
                      <p className="text-exo-light color-light-grey mb-0 text-center w-100">
                        - No se encuentran webhooks -
                      </p>
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Box>
    );
  }

  validar = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  validarWebhook = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
    } else {
      this.validar(event);
      this.createWebhook();
    }
  };
}

const mapStateToProps = (store) => {
  return {
    webhooks: store.webhooks,
    estados: store.estados,
  };
};

export default connect(mapStateToProps)(Webhooks);
