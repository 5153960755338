import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";
import url from "../../config";
import Utils from "../../assets/utils/Utils";
import { VERSION } from "../../assets/utils/Helpers";
import "./Login.css";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Cargando from "../../assets/components/cargando/Cargando";
import IconoRayo from "../../assets/icons/logo-rayo-blue.png";
import OjoIcon from "../../assets/icons/icono-eye-black.svg";
import OjoCloseIcon from "../../assets/icons/oculto.svg";

const configOptions = {
  apiKey: "AIzaSyC8G97DjLvSt-dcwY6ut2sdH90Q8en7TK8",
  authDomain: "thor-fm.firebaseapp.com",
  databaseURL: "https://thor-fm.firebaseio.com/",
  projectId: "thor-fm",
  storageBucket: "thor-fm.appspot.com",
  messagingSenderId: "577250335555",
  appId: "1:577250335555:web:be85fa1a2056dda2a1edf6",
  measurementId: "G-RKSFNRSFP2",
};

firebase.initializeApp(configOptions);

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenPassword: true,
      username: "",
      password: "",
      cargando: false,
      resetPassword: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCargando = (value) => {
    this.setState({
      cargando: value,
    });
  };

  toggleShow() {
    this.setState({ hiddenPassword: !this.state.hiddenPassword });
  }

  componentDidMount() {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (user === "null" || typeof user === undefined || user === null) {
      if (this.props.password) {
        this.setState({ password: this.props.password });
      }
    } else {
      if (user.type === 0 || user.type === 2) {
        this.props.history.push("/Home/Mapa");
        window.location.href = "/Home/Mapa";
      } else {
        this.props.history.push("/tasks");
        window.location.href = "/tasks";
      }
    }
  }

  validarDispatcher = async () => {
    this.handleCargando(true);
    try {
      await axios({
        method: "get",
        url:
          url.host +
          "/Dispatchers/dispatcher_by_email/" +
          this.state.username.toLowerCase() +
          "/",
      }).then(({ status, data }) => {
        if (status === 200) {
          this.ingresar(data.customer_dispatcher, data, data.dni_dispatcher);
        } else {
          this.handleCargando(false);
          Utils.handleAlert(
            "Error",
            "Ha ocurrido un error al iniciar sesión, vuelva a intentarlo",
            "Ok"
          );
        }
      });
    } catch (error) {
      let titulo = "Error";
      let mensaje =
        "Ha ocurrido un error al iniciar sesión, vuelva a intentarlo";
      if (error.message.substr(-3) === "404") {
        titulo = "Usuario no autorizado";
        mensaje = "El usuario ingresado no es un usuario autorizado";
      }
      this.handleCargando(false);
      Utils.handleAlert(titulo, mensaje, "Ok");
    }
  };

  ingresar = (clientes, dispatcher, dni) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        firebase
          .auth()
          .signInWithEmailAndPassword(
            this.state.username.toLowerCase(),
            this.state.password
          )
          .then(async (data) => {
            this.handleCargando(false);
            let idClientes = [];
            let isRayo = false;
            clientes.forEach((element) => {
              if (element.idCustomer === "5f0c95b1495f530ee0dbe9ff") {
                isRayo = true;
              }
              idClientes.push(element.idCustomer);
            });

            if (dispatcher.role_dispatcher != null) {
              switch (dispatcher.role_dispatcher) {
                case "Admin":
                  let usuario = {
                    name: dispatcher.name_dispatcher,
                    email: dispatcher.mail_dispatcher,
                    photoURL: dispatcher.image_dispatcher,
                    phoneNumber: "+ " + dispatcher.number_dispatcher,
                    customers: isRayo ? [] : idClientes,
                    id: dispatcher._id,
                    type: 0, // DISPATCHER
                  };

                  sessionStorage.setItem("user", JSON.stringify(usuario));
                  this.props.history.push("/Home/Mapa");
                  break;

                case "EPA":
                  await axios({
                    method: "get",
                    url: url.host + "/Providers/provider/dni/" + dni + "/",
                  }).then(({ status, data }) => {
                    if (status === 200) {
                      if (data.lenght === 0) {
                        Utils.handleAlert(
                          "Error en la autenticación",
                          "Este perfil tipo EPA no esta relacionado con ningún proveedor existente",
                          "Ok"
                        );
                      } else {
                        let filtrosGlobalesRayo = JSON.parse(
                          sessionStorage.getItem("filtrosRayo")
                        );
                        let newFilters = [
                          { filter: "idProvider", data: data[0]._id },
                        ];
                        let newFiltrosGlobalesRayo = Utils.changeFilterRayo(
                          newFilters,
                          filtrosGlobalesRayo
                        );
                        sessionStorage.setItem(
                          "filtrosRayo",
                          JSON.stringify(newFiltrosGlobalesRayo)
                        );

                        let epa = {
                          name: dispatcher.name_dispatcher,
                          email: dispatcher.mail_dispatcher,
                          photoURL: dispatcher.image_dispatcher,
                          phoneNumber: "+ " + dispatcher.number_dispatcher,
                          customers: isRayo ? [] : idClientes,
                          type: 2, // EPA
                        };

                        sessionStorage.setItem("user", JSON.stringify(epa));
                        this.props.history.push("/Home/Mapa");
                      }
                    } else {
                      this.handleCargando(false);
                      Utils.handleAlert(
                        "Error",
                        "Ha ocurrido un error al iniciar sesión, vuelva a intentarlo",
                        "Ok"
                      );
                    }
                  });
                  break;

                default:
                  let observer = {
                    name: dispatcher.name_dispatcher,
                    email: dispatcher.mail_dispatcher,
                    photoURL: dispatcher.image_dispatcher,
                    phoneNumber: "+ " + dispatcher.number_dispatcher,
                    customers: isRayo ? [] : idClientes,
                    type: 1, // OBSERVER
                  };

                  sessionStorage.setItem("user", JSON.stringify(observer));
                  this.props.history.push("/tasks");
                  break;
              }
            }
          })
          .catch((err) => {
            this.handleCargando(false);
            if (
              err.message ===
                "There is no user record corresponding to this identifier. The user may have been deleted." ||
              err.message ===
                "The password is invalid or the user does not have a password."
            ) {
              Utils.handleAlert(
                "Credenciales incorrectas",
                "Su nombre de usuario o contraseña no son correctas, intente nuevamente",
                "Ok"
              );
            } else {
              Utils.handleAlert(
                "Error en la autenticación",
                "No se pudo tener acceso al servicio de autenticación",
                "Ok"
              );
            }
          });
      });
  };

  resetPassword = () => {
    this.handleCargando(true);
    firebase
      .auth()
      .signOut()
      .then(() => {
        firebase
          .auth()
          .sendPasswordResetEmail(this.state.username)
          .then((data) => {
            this.handleCargando(false);
            Utils.handleAlert(
              "Correo enviado",
              "Se ha enviado un correo electrónico a " +
                this.state.username +
                " con instrucciones sobre cómo restablecer su contraseña",
              "Ok"
            );
          })
          .catch((err) => {
            this.handleCargando(false);
            Utils.handleAlert(
              "Error",
              "No se ha podido enviar el correo electrónico, intente nuevamente",
              "Ok"
            );
          });
      });
  };

  render() {
    return (
      <div className="login-alto w-100 background-login">
        <div className="row m-0 login-alto">
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            {this.state.resetPassword ? (
              <div className="w-100 div-login p-5">
                <Typography
                  variant="h5"
                  gutterBottom
                  color="primary"
                  align="center"
                >
                  Restablecer contraseña
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Ingrese su correo electrónico para recibir instrucciones sobre
                  cómo restablecer su contraseña.
                </Typography>

                <form onSubmit={this.validarReset}>
                  <div className="form-group mt-4">
                    <input
                      required
                      type="email"
                      className="form-control input-login form-control-login"
                      placeholder="Correo Electrónico"
                      name="username"
                      value={this.state.username}
                      onChange={this.handleChange}
                    />
                  </div>

                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className="w-100 mt-3"
                  >
                    Restablecer contraseña
                  </Button>
                  <div className="d-flex justify-content-center mt-2">
                    <Typography
                      variant="body1"
                      gutterBottom
                      className="href"
                      color="primary"
                      onClick={() => this.setState({ resetPassword: false })}
                    >
                      Volver a Login
                    </Typography>
                  </div>
                </form>
              </div>
            ) : (
              <div className="w-100 div-login p-5">
                <img
                  alt="IconoRayo"
                  src={IconoRayo}
                  className="img-fluid img-login d-block mx-auto mb-4"
                />
                <form onSubmit={this.validarLogin}>
                  <div className="form-group">
                    <Typography variant="body1" gutterBottom>
                      Usuario
                    </Typography>
                    <input
                      required
                      type="email"
                      className="form-control input-login form-control-login"
                      placeholder="Correo Electrónico"
                      name="username"
                      value={this.state.username}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <Typography variant="body1" gutterBottom>
                      Contraseña
                    </Typography>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <input
                          required
                          className="form-control input-login-password"
                          placeholder="Ingrese su contraseña"
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          name="password"
                          type={this.state.hiddenPassword ? "password" : "text"}
                          value={this.state.password}
                          onChange={this.handleChange}
                        />
                        <div className="input-group-append login">
                          <button
                            className="btn btn-outline-secondary input-login-button"
                            type="button"
                            id="button-addon2"
                          >
                            <img
                              alt="OjoIcon"
                              src={
                                this.state.hiddenPassword
                                  ? OjoIcon
                                  : OjoCloseIcon
                              }
                              className={
                                this.state.hiddenPassword
                                  ? "input-login-icon d-flex align-items-center"
                                  : "input-login-icon-closed d-flex align-items-center"
                              }
                              onClick={this.toggleShow}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className="w-100 mt-3"
                  >
                    INICIAR SESIÓN
                  </Button>
                  <div className="d-flex justify-content-center mt-2">
                    <Typography
                      variant="body1"
                      gutterBottom
                      className="href"
                      color="primary"
                      onClick={() => this.setState({ resetPassword: true })}
                    >
                      ¿Olvidaste tu contraseña?
                    </Typography>
                  </div>
                  <p className="w-100 text-center txt-carta mt-0">
                    {"v " + VERSION}
                  </p>
                </form>
              </div>
            )}
          </div>
          <div className="col-md-6 sin-espacios"></div>
        </div>

        <Cargando cargando={this.state.cargando} />
      </div>
    );
  }

  validar = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  validarLogin = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
    } else {
      this.validar(event);
      this.validarDispatcher();
    }
  };

  validarReset = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
    } else {
      this.validar(event);
      this.resetPassword();
    }
  };
}

export default Login;
