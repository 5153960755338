import url from '../../config';
import axios from 'axios';
import Utils from '../../assets/utils/Utils';

export const OBTENER_PROVEEDORES = 'OBTENER_PROVEEDORES';
export const obtenerProveedores = () => {
    return async (dispatch) => {
        try {
            await axios({
                method: 'get',
                url: url.host + '/Providers/providers/',
                headers: [],
            })
                .then((response) => {
                    switch (response.status) {
                        case 200:
                            let providersSort = response.data.sort(function (a, b) {
                                if (a.name_provider < b.name_provider) return -1;
                                if (a.name_provider > b.name_provider) return 1;
                                return 0;
                            });
                            dispatch({
                                type: OBTENER_PROVEEDORES,
                                proveedores: providersSort,
                            });
                            break;

                        default:
                            Utils.handleAlert(
                                'Error ' + response.status,
                                'Ha ocurrido un error al obtener comunas',
                                'Ok'
                            );
                            break;
                    }
                })
                .catch((e) => {
                    Utils.handleAlert('Error', e.message, 'Ok');
                });
        } catch (e) {
            Utils.handleAlert('Error', e.message, 'Ok');
        }
    };
};
