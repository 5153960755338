import React from 'react';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import IconoFlechaAzul from '../../icons/icono-flecha-derecha-blue2.png';

class Historial extends React.Component {
    nombreEvidencia = (evidencia) => {
        switch (evidencia) {
            case 'firma':
            case 'nota':
                return evidencia;
            case 'codigoBarras':
                return 'código de barras';
            case 'codigoQR':
                return 'código QR';
            case 'dropdown':
                return 'dropdown';
            default:
                return 'fotografia';
        }
    };
    render() {
        const Template = ({ template, nombre, tipo }) => {
            return (
                <div className="row m-0 px-2 pt-2 pb-0 mt-2">
                    <div className="col-custom-10 d-flex align-items-center">
                        <div className="dot-orden-list back-medium-grey"></div>
                    </div>
                    <div className="col-custom-90 pr-0">
                        <div className="row m-0 p-2 carta-orden">
                            <p className="txt-carta-name text-roboto-regular mb-1 txt-no-asignado w-100">
                                {'Evidencia de rayo ' + nombre}
                            </p>
                            {tipo === 'foto' ? (
                                <div className="w-100 d-flex justify-content-center">
                                    <img
                                        alt="Imagen guardada de template"
                                        src={template}
                                        style={{ width: '90%', height: 'auto', borderRadius: 10 }}
                                    />
                                </div>
                            ) : (
                                <p className="txt-carta-name text-roboto-regular mb-0">{template}</p>
                            )}
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <div className={this.props.historialState ? 'paper p-0' : 'paper p-0 d-none'}>
                <div className="row m-0 pt-2">
                    <div className="col-2 pr-0 pl-1">
                        <IconButton
                            aria-label="volver"
                            style={{ paddingRight: '16px', paddingLeft: '16px' }}
                            onClick={() => this.props.handleState('historial', false)}
                        >
                            <img src={IconoFlechaAzul} width="8" height="15" alt="volver" className="rotar-icono-180" />
                        </IconButton>
                    </div>
                    <div className="col-8 p-0">
                        <h6 className="title-text text-center mt-2 mb-3">{'Historial ' + this.props.titulo}</h6>
                    </div>
                    <div className="col-2 p-0"></div>
                </div>

                {this.props.template.length !== 0 ? (
                    <div>
                        {this.props.template.map((template) => (
                            <>
                                {!template.foto ? (
                                    false
                                ) : (
                                    <Template template={template.foto} nombre={'fotografia'} tipo={'foto'} />
                                )}
                                {!template.codigoQR ? (
                                    false
                                ) : (
                                    <Template template={template.codigoQR} nombre={'código QR'} tipo={'texto'} />
                                )}
                                {!template.codigoBarras ? (
                                    false
                                ) : (
                                    <Template
                                        template={template.codigoBarras}
                                        nombre={'código de barras'}
                                        tipo={'texto'}
                                    />
                                )}
                                {!template.nota ? (
                                    false
                                ) : (
                                    <Template template={template.nota} nombre={'nota'} tipo={'texto'} />
                                )}
                                {!template.firma ? (
                                    false
                                ) : (
                                    <Template template={template.firma} nombre={'firma'} tipo={'foto'} />
                                )}
                                {!template.dropdown ? (
                                    false
                                ) : (
                                    <Template template={template.dropdown} nombre={'dropdown'} tipo={'texto'} />
                                )}
                            </>
                        ))}
                    </div>
                ) : (
                    false
                )}

                {this.props.historial.length === 0 ? (
                    <div className="row m-0 mb-3 px-3 pt-3">
                        <p className="w-100 text-center text-exo-light color-light-grey">
                            {'Este ' + this.props.titulo + ' no posee historial'}
                        </p>
                    </div>
                ) : (
                    this.props.historial.map((historia, i) => {
                        if (this.props.historial.length === i + 1) {
                        } else {
                        }
                        return (
                            <div className="row m-0 px-2 pt-2 pb-0 mt-2" key={i}>
                                <div className="col-custom-10 d-flex align-items-center">
                                    <div className="dot-orden-list back-medium-grey"></div>
                                </div>
                                <div className="col-custom-90 pr-0">
                                    <div className="row m-0 p-2 carta-orden">
                                        <p className="txt-carta-name text-roboto-regular mb-0 txt-no-asignado w-100">
                                            {moment(historia.date).utcOffset('+00').format('DD-MM-YYYY HH:mm')}
                                        </p>
                                        <p className="txt-carta-name text-roboto-regular mb-0">{historia.description}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        );
    }
}

export default Historial;
