import React from "react";
import MarkerClusterer from "@google/markerclustererplus";
import cloneDeep from "lodash/cloneDeep";
import { MAP_CONFIG } from "../../utils/Helpers";
import Utils from "../../utils/Utils";

class MapaGoogle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: false,
    };
  }

  componentDidMount = async () => {
    this.map = new window.google.maps.Map(
      document.getElementById("googlemaps1"),
      MAP_CONFIG
    );
    if (this.props.localizaciones) this.handleMarkers(false);
    if (this.props.localizacionesRayos) this.handleMarkersRayos(false);
  };

  componentWillUnmount = async () => {
    // this.clusters.clearMarkers();
    // console.log('---componentWillUnmount 0---');
  };

  componentDidUpdate = async (prevProps) => {
    // console.log('-componentDidUpdate- 0')
    try {
      if (prevProps.mapaId && prevProps.mapaId !== this.props.mapaId) {
        // CAMBIO DE TAB
        if (prevProps.localizaciones) {
          if (prevProps.localizaciones.length !== 0) {
            try {
              await prevProps.localizaciones.forEach((element) => {
                if (this["markertask" + element.task]) {
                  this["markertask" + element.task].setMap(null);
                  this["markertask" + element.task] = undefined;
                }
              });
              await this.clusters.clearMarkers();
            } catch (error) {}
            this.handleMarkers(false);
          } else {
            this.handleMarkers(false);
          }
        }
      } else {
        // MARKERS TAREAS VISTA MAPA
        if (prevProps.localizaciones && this.props.localizaciones)
          if (
            this.props.vista === "mapa" &&
            prevProps.localizaciones !== this.props.localizaciones
          ) {
            if (
              prevProps.localizaciones.length ===
              this.props.localizaciones.length
            ) {
              this.handleMarkers(true, this.props.localizaciones, 2);
            } else {
              this.handleMarkers(true, this.props.localizaciones, 1);
            }
          }
      }

      if (
        this.props.vista === "tarea" &&
        this.props.localizaciones !== prevProps.localizaciones
      )
        // MARKERS VISTA DE TAREA
        this.handleMarkers(true);

      // MARKERS RAYOS VISTA MAPA
      if (prevProps.localizacionesRayos && this.props.localizacionesRayos)
        if (this.props.localizacionesRayos !== prevProps.localizacionesRayos) {
          if (
            prevProps.localizacionesRayos.length ===
            this.props.localizacionesRayos.length
          ) {
            this.handleMarkersRayos(
              true,
              prevProps.localizacionesRayos,
              this.props.localizacionesRayos,
              2
            );
          } else {
            this.handleMarkersRayos(
              true,
              prevProps.localizacionesRayos,
              this.props.localizacionesRayos,
              1
            );
          }
        }

      if (
        this.props.detalleRayo &&
        this.props.centerRayo &&
        !prevProps.centerRayo
      ) {
        this.handleCenter(this.props.options.center);
      }

      // CENTRAR MAPA EN RAYO
      if (this.props.detalleRayo && !prevProps.detalleRayo) {
        // ABRIR INFOWINDOW
        this.handleCenter(this.props.options.center);
      } else if (prevProps.detalleRayo !== this.props.detalleRayo) {
        this.map.setOptions(MAP_CONFIG);
        if (prevProps.rayo) {
          let rayo = this["rayo" + prevProps.rayo._id];
          if (rayo) {
            // CERRAR INFOWINDOW
            window.google.maps.event.trigger(this.map, "click", {
              latLng: new window.google.maps.LatLng(0, 0),
            });
          }
        }
      }

      // CENTRAR MAPA EN GT
      if (this.props.detalleGT && !prevProps.detalleGT) {
        // ABRIR INFOWINDOW
        this.handleCenterGT(this.props.localizacionesGT);
      } else if (prevProps.detalleGT !== this.props.detalleGT) {
        this.map.setOptions(MAP_CONFIG);
        if (prevProps.localizacionesGT) {
          // CERRAR INFOWINDOW
          prevProps.localizacionesGT.forEach((element) => {
            this["circle" + element.task].setMap(null);
            this["circle" + element.task] = undefined;
          });
          // window.google.maps.event.trigger(this.map, 'dblclick', {
          //     latLng: new window.google.maps.LatLng(0, 0),
          // });
        }
      }
    } catch (error) {}
  };

  handleCenter = (center) => {
    try {
      let bounds = new window.google.maps.LatLngBounds();
      let myLatLng = new window.google.maps.LatLng(center.lat, center.lng);
      bounds.extend(myLatLng);
      this.map.fitBounds(bounds);

      if (this.props.rayo) {
        let rayo = this["rayo" + this.props.rayo._id];
        if (rayo) {
          window.google.maps.event.trigger(rayo, "click", {
            latLng: new window.google.maps.LatLng(0, 0),
          });
        }
      }
    } catch (error) {}
  };

  handleCenterGT = async (localizaciones) => {
    let bounds = new window.google.maps.LatLngBounds();

    let radius = 150;
    if (localizaciones.length === 1) {
      radius = 30;
    }
    await localizaciones.forEach((element) => {
      // window.google.maps.event.trigger(this['markertask' + element.task], 'dblclick', {
      //     latLng: new window.google.maps.LatLng(0, 0),
      // });

      let color = this.colorTipoTareaDetalle(element.type);
      this["circle" + element.task] = new window.google.maps.Circle({
        strokeColor: color,
        strokeOpacity: 1,
        strokeWeight: 3,
        fillColor: color,
        fillOpacity: 0.35,
        map: this.map,
        center: element.position,
        radius: radius,
      });

      let myLatLng = new window.google.maps.LatLng(
        element.position.lat,
        element.position.lng
      );
      bounds.extend(myLatLng);
    });
    await this.map.fitBounds(bounds);
  };

  colorTipoTareaDetalle = (n) => {
    switch (n) {
      case 1:
        return "#f7ac06";
      case 2:
        return "#f25c22";
      default:
        return "#1b84bf";
    }
  };

  handleMarkers = (tipo, locations, accion) => {
    let markers = [];
    let bounds = new window.google.maps.LatLngBounds();

    if (this.props.vista === "tarea") {
      this.props.localizaciones.forEach((coordenadas, i) => {
        let myLatLng = new window.google.maps.LatLng(
          coordenadas.position.lat,
          coordenadas.position.lng
        );
        coordenadas.map = this.map;
        this["markertask" + coordenadas.task] = new window.google.maps.Marker(
          coordenadas
        );
        markers.push(this["markertask" + coordenadas.task]);
        bounds.extend(myLatLng);
      });
    } else if (this.props.vista === "mapa") {
      try {
        if (tipo) {
          if (accion === 1) {
            if (locations.length > this.clusters.getMarkers().length) {
              locations.forEach((element) => {
                let i = this.clusters
                  .getMarkers()
                  .findIndex((marker) => marker.task === element.task);
                if (i === -1) {
                  element.map = this.map;
                  this.addMarkerCluster(element);
                }
              });
            } else if (locations.length < this.clusters.getMarkers().length) {
              this.clusters.getMarkers().forEach((element, i) => {
                let j = locations.findIndex(
                  (marker) => marker.task === element.task
                );
                if (j === -1) {
                  this.clusters.removeMarker(this.clusters.getMarkers()[i]);
                }
              });
            }
          }
        } else {
          let markers = [];
          this.props.localizaciones.forEach((element) => {
            element.map = this.map;
            let myLatLng = new window.google.maps.LatLng(
              element.position.lat,
              element.position.lng
            );
            this.addMarker(element);
            markers.push(this["markertask" + element.task]);
            bounds.extend(myLatLng);
          });

          this.clusters = new MarkerClusterer(this.map, markers, {
            imagePath:
              "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            gridSize: 45,
          });

          // let infoWindow = new window.google.maps.InfoWindow();
          // window.google.maps.event.addListener(this.clusters, 'mouseover', (cluster) => {
          //     try {
          //         if (cluster) {
          //             var markers = cluster.getMarkers();
          //             let contenido = '';
          //             var num = 0;
          //             for (let i = 0; i < markers.length; i++) {
          //                 num++;
          //                 let content =
          //                     '<div class="border-bottom"><div class="row m-0">' +
          //                     '<label class="text-exo-bold mb-0 ' +
          //                     Utils.colorTipoTareaDetalle(markers[i].type, true) +
          //                     '">' +
          //                     Utils.colorTipoTareaDetalle(markers[i].type, false) +
          //                     '</label>' +
          //                     '</div>' +
          //                     '<div id="bodyContent">' +
          //                     '<p class="text-roboto-regular mb-0">Order ID: ' +
          //                     markers[i].title +
          //                     '</p>' +
          //                     '</div></div>';
          //                 contenido = contenido + content;
          //             }
          //             const contentString = '<div id="content">' + '<h6>' + num.toString() + ' MARKERS</h6>' + contenido + '</div>';

          //             infoWindow.setContent(contentString);
          //             infoWindow.setPosition(cluster.getCenter());
          //             infoWindow.open(this.map);
          //         }
          //     } catch (error) {
          //         // console.log('----- error -----');
          //         // console.log(error);
          //     }
          // });

          // window.google.maps.event.addListener(this.map, 'click', () => {
          //     infoWindow.close();
          // });
        }
      } catch (error) {
        // console.log(error);
      }
    }

    if (this.props.fitBounds) {
      if (this.props.localizaciones.length === 0) {
        this.map.setOptions(MAP_CONFIG);
      } else {
        this.map.fitBounds(bounds);
        //this.map.panTo(center)
        // let center = bounds.getCenter()
      }
    }

    if (!this.props.fitBounds)
      window.google.maps.event.addListener(
        this.clusters,
        "clusterclick",
        this.props._handleClickCluster
      );
  };

  handleMarkersRayos = (tipo, prevRayos, rayos, accion) => {
    try {
      if (tipo) {
        if (accion === 1) {
          prevRayos.forEach((element, i) => {
            this["rayo" + element.placeName].setMap(null);
          });
          this.addMarkersRayos(this.props.localizacionesRayos);
        } else {
          let found = [];
          prevRayos.forEach((prev, i) => {
            rayos.forEach((actual, j) => {
              if (
                prev.placeName === actual.placeName &&
                (prev.position.lat !== actual.position.lat ||
                  prev.position.lng !== actual.position.lng)
              ) {
                found.push(prev);
              }
            });
          });

          found.forEach((element) => {
            this["rayo" + element.placeName].setMap(null);
            this.addMarkerRayo(element, element);
          });
        }
      } else {
        this.addMarkersRayos(this.props.localizacionesRayos);
      }
    } catch (error) {}
  };

  addMarkerRayo = (i, marker) => {
    if (marker) {
      marker.map = this.map;
      let rayo = cloneDeep(marker.dataRayo);
      let marca = {
        icon: marker.icon,
        position: marker.position,
        map: marker.map,
        title: marker.title,
        placeName: marker.placeName,
      };

      this["rayo" + marker.placeName] = new window.google.maps.Marker(marca);
      const contentString =
        '<div id="content">' +
        '<div class="row m-0">' +
        '<div class="d-inline d-flex align-items-center mb-1 ">' +
        '<div class="m-0 ' +
        this.estadoRayo(rayo.status) +
        '"></div>' +
        "</div>" +
        '<label class="text-exo-bold mb-1 pl-2">' +
        rayo.name +
        "</label>" +
        "</div>" +
        '<div id="bodyContent">' +
        '<p class="text-roboto-regular mb-0"> ID: ' +
        rayo.id +
        "</p>" +
        '<p class="text-roboto-regular mb-0"> DNI: ' +
        rayo.dni +
        "</p>" +
        '<p class="text-roboto-regular mb-0"> Número de contacto: ' +
        rayo.phone +
        "</p>" +
        '<p class="text-roboto-regular mb-0"> Email: ' +
        rayo.email +
        "</p>" +
        "</div>" +
        "</div>";

      const infowindow = new window.google.maps.InfoWindow({
        closeBoxURL: marker.placeName,
        content: contentString,
      });

      this["rayo" + marker.placeName].addListener("click", () => {
        infowindow.open(this.map, this["rayo" + marker.placeName]);
      });
      window.google.maps.event.addListener(this.map, "click", () => {
        infowindow.close();
      });

      this["rayo" + marker.placeName].setMap(this.map);
    }
  };

  addMarkerCluster = (element) => {
    element.map = this.map;
    this["markertask" + element.task] = new window.google.maps.Marker(element);
    this["markertask" + element.task].addListener("click", (e) =>
      this.props._handleClickMarker(e, element.placeName)
    );

    const infowindow = new window.google.maps.InfoWindow({
      closeBoxURL: element.title,
      content: this.infowindowTask(element),
    });

    this["markertask" + element.task].addListener("dblclick", () => {
      infowindow.open(this.map, this["markertask" + element.task]);
    });
    window.google.maps.event.addListener(this.map, "dblclick", () => {
      infowindow.close();
    });

    this["markertask" + element.task].setMap(this.map);
    this.clusters.addMarker(this["markertask" + element.task]);
  };

  addMarker = (element) => {
    element.map = this.map;
    this["markertask" + element.task] = new window.google.maps.Marker(element);
    this["markertask" + element.task].addListener("click", (e) =>
      this.props._handleClickMarker(e, element.placeName)
    );

    const infowindow = new window.google.maps.InfoWindow({
      closeBoxURL: element.title,
      content: this.infowindowTask(element),
    });

    this["markertask" + element.task].addListener("dblclick", () => {
      infowindow.open(this.map, this["markertask" + element.task]);
    });
    window.google.maps.event.addListener(this.map, "dblclick", () => {
      infowindow.close();
    });

    this["markertask" + element.task].setMap(this.map);
  };

  addMarkersRayos = (rayos) => {
    rayos.forEach((coordenada, i) => {
      this.addMarkerRayo(i, coordenada);
    });
  };

  estadoRayo = (status) => {
    if (status === 1) {
      return "dot activo";
    } else if (status === 3) {
      return "dot activo";
    } else {
      return "dot inactivo";
    }
  };

  infowindowTask = (task) => {
    const contentString =
      '<div id="content">' +
      '<div class="row m-0">' +
      '<label class="text-exo-bold mb-1 ' +
      Utils.colorTipoTareaDetalle(task.type, true) +
      '">' +
      Utils.colorTipoTareaDetalle(task.type, false) +
      "</label>" +
      "</div>" +
      '<div id="bodyContent">' +
      '<p class="text-roboto-regular mb-0">Order ID: ' +
      task.title +
      "</p>" +
      '<p class="text-roboto-regular mb-0"> Contacto: ' +
      task.contact +
      "</p>" +
      "</div>" +
      "</div>";

    return contentString;
  };

  render() {
    return (
      <div
        className="home-screen"
        style={{ width: "100%", height: "100%" }}
        id="googlemaps1"
      ></div>
    );
  }
}

export default MapaGoogle;
