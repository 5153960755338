import React from "react";

import Swal from "sweetalert2";
import ListaTareasRayo from "../../components/lists/ListaTareasRayo";
import IconoPhone from "../../icons/icono-phone-black.svg";
import EmailIcon from "@material-ui/icons/Email";
import BotonesTareasSeleccionadas from "../home/BotonesTareasSeleccionadas";
import * as rayosAccion from "../../../store/actions/Rayos";
import clsx from "clsx";

import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import CloseIcon from "@material-ui/icons/Close";

import TextFieldOutlined from "../../components/custom/TextFieldOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import Utils from "../../utils/Utils";

class DetalleRayo extends React.Component {
  handlePanels = (panel) => {
    this.props.handleState("optimizarBacklog", !this.props.optimizar);
  };

  optimizarTareas = async () => {
    if (this.props.optimizar === false) {
      Swal.fire({
        title: "¿Optimizar tareas?",
        text: "Si confirma, las tareas seleccionadas se optimizarán, por lo que estas serán re-organizadas de forma permanente",
        imageUrl:
          "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg",
        showCancelButton: true,
        customClass: {
          image: "imageSweetAlert",
          title: "titleSweetAlert",
          content: "textSweetAlert",
          confirmButton: "buttonSweetAlert",
          cancelButton: "btn-secondary",
        },
        confirmButtonText: "Si, optimizar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          this.props.handleCargando(true);
          try {
            const { dispatch } = this.props;
            let optimizar = rayosAccion.tasksOptimization(
              this.props.rayo,
              this.props.tareasRayo
            );
            await dispatch(optimizar);
          } catch (error) {}
          this.handlePanels("optimizar");
          this.props.handleCargando(false);
        }
      });
    } else {
      this.handlePanels("optimizar");
    }
  };

  handleAsignar = (rayos) => {
    this.props.handleAsignarBacklog(rayos);
    this.props.handleState("asignarBacklog", true);
  };

  render() {
    const { classes } = this.props;
    const TabPanelTask = ({ children, value, index, ...other }) => {
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={"wrapped-tabpanel-" + index}
          aria-labelledby={"wrapped-tab-" + index}
          {...other}
        >
          {value === index && <Box>{children}</Box>}
        </div>
      );
    };

    return (
      <div className={Utils.handleVistasVisibles(this.props.panelRayos)}>
        <div
          className={clsx(
            "w-100 alto-dark d-flex align-items-center",
            classes.bgPrimary
          )}
        >
          <div className="row m-0 w-100">
            <div className="col-11 pr-0 d-flex align-items-center">
              <h5
                className={clsx(
                  "mb-0 text-roboto-regular",
                  classes.textBgPrimary
                )}
              >
                RAYOS / {this.props.rayo.code_rayo}
              </h5>
            </div>
            <div className="col-1 pl-0 d-flex align-items-center justify-content-end">
              <IconButton
                aria-label="cerrar"
                className="p-2"
                onClick={() => {
                  this.props.handlePanels("detalleRayos");
                  this.props.handleCenter("cerrar", []);
                  this.props.handlePanels("panelRayos");
                  if (this.props.tareasRayo !== null) {
                    this.props.cleanSelected();
                  }
                  this.props.handleState("detalleRayo", "0");
                  this.props.handleState("asignarBacklog", false);
                  this.props.handleState("rayoCoordenadas", []);
                  this.props.handleState("optimizarBacklog", false);
                  this.props.vaciarBacklog();
                }}
              >
                <CloseIcon className={classes.textBgPrimary} />
              </IconButton>
            </div>
          </div>
        </div>
        <Tabs
          value={this.props.detalleRayo}
          onChange={(e, newValue) =>
            this.props.handleState("detalleRayo", newValue)
          }
          aria-label="wrapped label tabs example"
          className="w-100 tabs-task"
        >
          <Tab
            value={"0"}
            label={"Detalles"}
            {...Utils.a11yProps("0")}
            className="tab-rayos"
          />
          <Tab
            value={"1"}
            label={"Tareas"}
            {...Utils.a11yProps("1")}
            className="tab-rayos"
          />
          {/* <Tab
            value={"2"}
            label={"Cronograma"}
            {...Utils.a11yProps("2")}
            className="tab-rayos"
          /> */}
        </Tabs>
        <TabPanelTask
          value={this.props.detalleRayo}
          className="paper p-relative"
          index="0"
        >
          <div
            className="ver-copiado-gt hidden"
            id={"detalle-" + this.props.rayo._id}
          >
            <label>¡Copiado!</label>
          </div>
          <div className="row m-0 py-2 px-3 mb-3">
            <div className="col-5 pr-0">
              <p className="mb-0 sub-txt-carta text-roboto-regular text-center">
                {this.props.rayo.status_rayo === 2
                  ? "Fuera de servicio"
                  : "En servicio"}
              </p>
            </div>
            <div className="col-2 p-0 d-flex justify-content-center">
              <AntSwitch
                checked={this.props.rayo.status_rayo === 2 ? false : true}
                onChange={() =>
                  this.props.statusRayo(
                    this.props.rayo._id,
                    this.props.rayo.status_rayo
                  )
                }
                name="checkedC"
              />
            </div>
            <div className="col-5 pl-0">
              <p className="mb-0 sub-txt-carta text-roboto-regular text-center">
                {this.props.rayo.status_rayo === 1
                  ? "Rayo activo"
                  : this.props.rayo.status_rayo === 3
                  ? "Rayo ocupado"
                  : "Rayo inactivo"}
              </p>
            </div>
          </div>
          <div className="row m-0 py-2 px-3 mb-3">
            <div className="col-3 pr-0 text-right">
              <img
                src={this.props.rayo.image_rayo}
                height="50"
                alt="user"
                className="img-rayo"
              />
            </div>
            <div className="col-9">
              <h5 className="mb-0 text-left">{this.props.rayo.name_rayo}</h5>
            </div>
          </div>
          <div className="row m-0 py-2 px-3">
            <div className="col-3 pr-0 text-right">
              <img src={IconoPhone} height="20" alt="phone" />
            </div>
            <div className="col-9">
              <Typography variant="body1">Teléfono</Typography>
              <Typography
                variant="body1"
                className="cursor-pointer pl-2"
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: 4,
                }}
                onClick={() =>
                  Utils.justCopyText2(
                    "detalle-" + this.props.rayo._id,
                    this.props.rayo.number_rayo
                  )
                }
              >
                {this.props.rayo.number_rayo}
              </Typography>
            </div>
          </div>

          <div className="row m-0 py-2 px-3">
            <div className="col-3 pr-0 text-right">
              <EmailIcon style={{ color: "black" }} fontSize="small" />
            </div>
            <div className="col-9">
              <Typography variant="body1">Email</Typography>
              <Typography
                variant="body1"
                className="cursor-pointer pl-2"
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: 4,
                }}
                onClick={() =>
                  Utils.justCopyText2(
                    "detalle-" + this.props.rayo._id,
                    this.props.rayo.email_rayo
                  )
                }
              >
                {this.props.rayo.email_rayo}
              </Typography>
            </div>
          </div>

          <div className="row m-0 py-2 px-3">
            <div className="col-3 pr-0 text-right text-exo-bold">
              <ContactPhoneIcon style={{ color: "black" }} fontSize="small" />
            </div>
            <div className="col-9">
              <Typography variant="body1">UUID</Typography>
              <Typography
                variant="body1"
                className="cursor-pointer pl-2 text-truncate"
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: 4,
                }}
                onClick={() =>
                  Utils.justCopyText2(
                    "detalle-" + this.props.rayo._id,
                    this.props.rayo.uuid_rayo
                  )
                }
              >
                {this.props.rayo.uuid_rayo}
              </Typography>
            </div>
          </div>

          <div className="row m-0 py-2 px-3">
            <div className="col-3 pr-0 text-right text-exo-bold">
              <PhoneAndroidIcon style={{ color: "black" }} fontSize="small" />
            </div>
            <div className="col-9">
              <Typography variant="body1">App Version</Typography>
              <Typography
                variant="body1"
                className="pl-2"
                style={{ backgroundColor: "#f2f2f2", borderRadius: 4 }}
              >
                {this.props.rayo.app_version ? (
                  this.props.rayo.app_version
                ) : (
                  <em>-No se encuentra registro-</em>
                )}
              </Typography>
            </div>
          </div>
          {/* 
          <div className="row m-0 py-2 px-3 mb-4">
            <div className="col-3 pr-0 text-right">
              <img src={IconoUbicacion} height="20" alt="ubicacion" />
            </div>
            <div className="col-9">
              <Typography variant="body1">Última ubicación</Typography>

              {typeof this.props.rayo.coordinateDirection === "undefined" ? (
                false
              ) : (
                <Typography
                  variant="body1"
                  className="cursor-pointer pl-2"
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 4,
                  }}
                  onClick={() =>
                    Utils.justCopyText2(
                      "detalle-" + this.props.rayo._id,
                      this.props.rayo.coordinateDirection
                    )
                  }
                >
                  {this.props.rayo.coordinateDirection}
                </Typography>
              )}
            </div>
          </div> */}

          <div className="d-flex justify-content-center mt-4">
            {/* <button
              type="button"
              className="btn-primary-rayo"
              onClick={this.props.centerMapRayo}
            >
              Buscar rayo en mapa
            </button> */}
            <Button
              type="button"
              className="btn-primary-rayo"
              onClick={this.props.centerMapRayo}
              variant="contained"
              color="secondary"
            >
              Buscar rayo en mapa
            </Button>
          </div>
        </TabPanelTask>
        <div
          role="tabpanel"
          hidden={this.props.detalleRayo !== "1"}
          id={"wrapped-tabpanel-1"}
          aria-labelledby={"wrapped-tab-1"}
        >
          {this.props.detalleRayo === "1" && (
            <Box
              className={
                this.props.seleccionTareas
                  ? "paper-mini"
                  : "cargando-paneles-rayo"
              }
            >
              {this.props.tareasRayo === null ? (
                <div className="cargando-paneles-rayo d-flex justify-content-center align-items-center">
                  <CircularProgress color="primary" />
                </div>
              ) : this.props.tareasRayo.length === 0 ? (
                <div className="cargando-paneles-rayo d-flex justify-content-center align-items-center">
                  <div className="w-100">
                    <p className="w-100 text-center text-exo-light color-light-grey mb-0">
                      No se encuentran tareas en el Backlog del Rayo
                    </p>
                    <p className="w-100 text-center text-exo-light color-light-grey">
                      <strong>{this.props.rayo.name_rayo}</strong>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="p-relative">
                  <div id="info-box-optimizar" className="d-none">
                    <p className="w-100 text-center text-exo-light color-light-grey mb-0">
                      Guardando nuevo orden de tareas...
                    </p>
                  </div>
                  <div id="info-box-optimizado" className="d-none">
                    <p className="w-100 text-center text-exo-light color-light-grey mb-0">
                      ¡Cambios guardados!
                    </p>
                  </div>
                  <div className="row m-0 p-2">
                    <div style={{ width: 120, display: "inline-flex" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.props.seleccionTareas}
                            onClick={() => {
                              if (this.props.seleccionTareas) {
                                this.props.cleanSelected();
                              } else {
                                this.props.checkAll();
                              }
                            }}
                            name="checkedA"
                            color="primary"
                            icon={
                              <CheckBoxOutlineBlankIcon
                                style={{ color: "#9999FF" }}
                              />
                            }
                          />
                        }
                        className="checkbox-rayo m-0"
                        label="Seleccionar todo"
                      />
                    </div>
                    <div
                      style={{
                        width: "calc(100% - 120px)",
                        display: "inline-flex",
                      }}
                    >
                      <TextFieldOutlined
                        size="small"
                        name="filtro_tareas_rayo"
                        placeholder="Buscar tareas"
                        value={this.props.filtro}
                        onChange={this.props.handleChange}
                        variant="outlined"
                        style={{ minWidth: "100%" }}
                      />
                    </div>
                  </div>
                  <ListaTareasRayo
                    clientes={this.props.clientes}
                    tasks={this.props.tareasRayo}
                    filtro_tareas={this.props.filtro}
                    handleCheck={this.props.handleCheck}
                    seleccionTareas={this.props.seleccionTareas}
                    showButtons={this.props.seleccionTareas}
                  />
                </div>
              )}
              {this.props.seleccionTareas ? (
                <BotonesTareasSeleccionadas
                  handleAsignarRayo={this.handleAsignar}
                  handlePanels={this.optimizarTareas}
                  optimizar={this.props.optimizar}
                  backlog={true}
                  rayos={this.props.rayos}
                  vista={"backlog"}
                />
              ) : (
                false
              )}
            </Box>
          )}
        </div>
        {/* <TabPanelTask
          value={this.props.detalleRayo}
          className="paper"
          index="2"
        >
          <div className="px-3 mt-5">
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                className="mini-table"
              >
                <TableHead className="mini-table-thead">
                  <TableRow>
                    <TableCell className="mini-table-th">Movimiento</TableCell>
                    <TableCell className="mini-table-th">Paro</TableCell>
                    <TableCell className="mini-table-th">Viajes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="mini-table-td">00:00:00</TableCell>
                    <TableCell className="mini-table-td">00:00:00</TableCell>
                    <TableCell className="mini-table-td">0km</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <p className="mb-0 mt-2 sub-txt-carta text-roboto-regular text-center">
              Zona horaria: GMT - 05:00 horas
            </p>
          </div>
        </TabPanelTask> */}
      </div>
    );
  }
}

const styles = (theme) => ({
  bgPrimary: {
    backgroundColor: theme.palette.primary.dark,
  },
  textBgPrimary: {
    color: theme.palette.primary.contrastText + " !important",
  },
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#1FC600",
        borderColor: "#1FC600",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default withStyles(styles, { withTheme: true })(DetalleRayo);
