import url from "../../config";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import Utils from "../../assets/utils/Utils";

export const ERROR_RAYOS = "ERROR_RAYOS";
export const RES_ERROR = "RES_ERROR";
export const error = () => {
  return async (dispatch) => {
    dispatch({ type: RES_ERROR });
  };
};

export const OBTENER_RAYOS = "OBTENER_RAYOS";
export const OBTENER_ALL_RAYOS = "OBTENER_ALL_RAYOS";
export const obtenerRayos = (filters, tipo) => {
  return async (dispatch) => {
    try {
      let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtrosRayo"));
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let query = "/Rayos/rayosBy/reduced?";
      if (usuario) if (usuario.type === 2) query = "/Rayos/rayos/reduced/epa/";

      let newFilters = cloneDeep(filters);
      if (tipo) {
        let clientes = Utils.getCustomerString();
        if (usuario)
          if (usuario.type === 2) {
            newFilters.push(
              { filter: "idCustomer", data: clientes },
              { filter: "idRayo", data: null }
            );
          } else {
            newFilters.push(
              { filter: "idCustomer", data: clientes },
              { filter: "idProvider", data: null },
              { filter: "idRayo", data: null }
            );
          }
      }

      let newFiltrosGlobales = Utils.changeFilterRayo(
        newFilters,
        filtrosGlobales
      );
      sessionStorage.setItem("filtrosRayo", JSON.stringify(newFiltrosGlobales));
      let stringifyFiltro = Utils.stringifyFiltersRayo(newFiltrosGlobales);
      if (usuario)
        if (usuario.type === 2)
          stringifyFiltro =
            Utils.stringifyFiltersRayoJustProvider(newFiltrosGlobales);

      let vigencia = "";
      if (usuario)
        if (usuario.type !== 2) {
          vigencia =
            stringifyFiltro === "" ? "vigencia=true" : "&vigencia=true";
        }

      await axios({
        method: "get",
        url: url.host + query + stringifyFiltro + vigencia,
        headers: [],
      })
        .then((response) => {
          switch (response.status) {
            case 200:
              let rayos = response.data.sort(function (a, b) {
                if (
                  a.name_rayo.toLowerCase().trim() <
                  b.name_rayo.toLowerCase().trim()
                )
                  return -1;
                if (
                  a.name_rayo.toLowerCase().trim() >
                  b.name_rayo.toLowerCase().trim()
                )
                  return 1;
                return 0;
              });
              const icon = {
                url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
                scaledSize: new window.google.maps.Size(20, 20),
                origin: new window.google.maps.Point(0, 0),
              };

              let arreglo = [];
              rayos.forEach((rayo) => {
                if (rayo.status_rayo === 1 || rayo.status_rayo === 3) {
                  let lat = rayo.latitude_rayo;
                  let lng = rayo.longitude_rayo;
                  let objeto = {
                    icon: icon,
                    position: { lat: parseFloat(lat), lng: parseFloat(lng) },
                    map: null,
                    title: rayo.name_rayo,
                    placeName: rayo._id,
                    dataRayo: {
                      id: rayo._id,
                      name: rayo.name_rayo,
                      dni: rayo.dni_rayo,
                      phone: rayo.number_rayo,
                      email: rayo.email_rayo,
                      status: rayo.status_rayo,
                    },
                  };
                  arreglo.push(objeto);
                }
              });

              if (tipo) {
                dispatch({
                  type: OBTENER_ALL_RAYOS,
                  rayos: rayos,
                  allRayos: rayos,
                  localidades: arreglo,
                });
              } else {
                dispatch({
                  type: OBTENER_RAYOS,
                  rayos: rayos,
                  localidades: arreglo,
                });
              }

              break;

            default:
              dispatch({
                type: ERROR_RAYOS,
                error:
                  "Error " +
                  response.status +
                  " al realizar la petición de obtener rayos",
              });
              break;
          }
        })
        .catch((e) => {
          dispatch({
            type: ERROR_RAYOS,
            error: "Error al realizar la petición de obtener rayos",
          });
        });
    } catch (e) {
      dispatch({
        type: ERROR_RAYOS,
        error: "Error al realizar la petición de obtener rayos",
      });
    }
  };
};

export const UPDATE_STATE_RAYOS = "UPDATE_STATE_RAYOS";
export const updateStateRayos = (rayos, rayoModificar) => {
  return async (dispatch) => {
    let listaRayos = cloneDeep(rayos);
    let rayo = cloneDeep(rayoModificar);
    const i = listaRayos.findIndex((element) => element._id === rayo.id);
    if (i !== -1) {
      listaRayos[i].status_rayo = rayo.status_rayo;
      listaRayos[i].latitude_rayo = rayo.latitude_rayo;
      listaRayos[i].longitude_rayo = rayo.longitude_rayo;

      let arreglo = [];
      const icon = {
        url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
        scaledSize: new window.google.maps.Size(20, 20),
        origin: new window.google.maps.Point(0, 0),
      };

      listaRayos.forEach((rayo) => {
        if (rayo.status_rayo === 1 || rayo.status_rayo === 3) {
          let lat = rayo.latitude_rayo;
          let lng = rayo.longitude_rayo;
          let objeto = {
            icon: icon,
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            map: null,
            title: rayo.name_rayo,
            placeName: rayo._id,
            dataRayo: {
              id: rayo._id,
              name: rayo.name_rayo,
              dni: rayo.dni_rayo,
              phone: rayo.number_rayo,
              email: rayo.email_rayo,
              status: rayo.status_rayo,
            },
          };
          arreglo.push(objeto);
        }
      });

      dispatch({
        type: UPDATE_STATE_RAYOS,
        rayos: listaRayos,
        localidades: arreglo,
      });
    }
  };
};

export const UPDATE_RAYOS = "UPDATE_RAYOS";
export const updateRayos = (rayos, rayoModificar) => {
  return async (dispatch) => {
    let listaRayos = cloneDeep(rayos);
    let rayo = cloneDeep(rayoModificar);
    const i = listaRayos.findIndex(
      (element) => element._id === rayo.idrayo_backlog
    );

    if (i !== -1) {
      listaRayos[i].tasks_in_progress_rayo = rayo.tasks_backlog.length;
      dispatch({
        type: UPDATE_STATE_RAYOS,
        rayos: listaRayos,
      });
    }
  };
};

export const ADD_RAYO = "ADD_RAYO";
export const addRayo = (rayos, rayoModificar) => {
  return async (dispatch) => {
    let listaRayos = cloneDeep(rayos);
    let rayoNuevo = cloneDeep(rayoModificar);
    listaRayos.push(rayoNuevo);

    let arreglo = [];
    const icon = {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: new window.google.maps.Size(20, 20),
      origin: new window.google.maps.Point(0, 0),
    };
    listaRayos.forEach((rayo) => {
      if (rayo.status_rayo === 1 || rayo.status_rayo === 3) {
        let lat = rayo.latitude_rayo;
        let lng = rayo.longitude_rayo;
        let objeto = {
          icon: icon,
          position: { lat: parseFloat(lat), lng: parseFloat(lng) },
          map: null,
          title: rayo.name_rayo,
          placeName: rayo._id,
          dataRayo: {
            id: rayo._id,
            name: rayo.name_rayo,
            dni: rayo.dni_rayo,
            phone: rayo.number_rayo,
            email: rayo.email_rayo,
            status: rayo.status_rayo,
          },
        };
        arreglo.push(objeto);
      }
    });

    dispatch({
      type: ADD_RAYO,
      rayos: listaRayos,
      localidades: arreglo,
      allRayos: listaRayos,
    });
  };
};

export const DELETE_RAYO = "DELETE_RAYO";
export const deleteRayo = (rayos, id) => {
  return async (dispatch) => {
    let listaRayos = cloneDeep(rayos);
    const i = listaRayos.findIndex((element) => element._id === id);
    if (i !== -1) {
      listaRayos.splice(i, 1);
      let arreglo = [];
      const icon = {
        url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
        scaledSize: new window.google.maps.Size(20, 20),
        origin: new window.google.maps.Point(0, 0),
      };
      listaRayos.forEach((rayo) => {
        if (rayo.status_rayo === 1 || rayo.status_rayo === 3) {
          let lat = rayo.latitude_rayo;
          let lng = rayo.longitude_rayo;
          let objeto = {
            icon: icon,
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            map: null,
            title: rayo.name_rayo,
            placeName: rayo._id,
            dataRayo: {
              id: rayo._id,
              name: rayo.name_rayo,
              dni: rayo.dni_rayo,
              phone: rayo.number_rayo,
              email: rayo.email_rayo,
              status: rayo.status_rayo,
            },
          };
          arreglo.push(objeto);
        }
      });

      dispatch({
        type: DELETE_RAYO,
        rayos: listaRayos,
        allRayos: listaRayos,
        localidades: arreglo,
      });
    }
  };
};

export const CREATE_RAYOS = "CREATE_RAYOS";
export const createRayos = (rayos, rayoCreado) => {
  return async (dispatch) => {
    let listaRayos = cloneDeep(rayos);
    let rayo = cloneDeep(rayoCreado);

    try {
      await axios({
        method: "get",
        url: url.host + "/Rayos/rayo/" + rayo.idrayo_backlog,
        headers: [],
      })
        .then((response) => {
          if (response.status === 200) {
            let newRayo = response.data;
            listaRayos.push(newRayo);

            const icon = {
              url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
              scaledSize: new window.google.maps.Size(20, 20),
              origin: new window.google.maps.Point(0, 0),
            };

            let arreglo = [];
            listaRayos.forEach((rayo) => {
              let lat = rayo.latitude_rayo;
              let lng = rayo.longitude_rayo;
              let objeto = {
                icon: icon,
                position: { lat: parseFloat(lat), lng: parseFloat(lng) },
                map: null,
                title: rayo.name_rayo,
                placeName: rayo._id,
                dataRayo: {
                  id: rayo._id,
                  name: rayo.name_rayo,
                  dni: rayo.dni_rayo,
                  phone: rayo.number_rayo,
                  email: rayo.email_rayo,
                  status: rayo.status_rayo,
                },
              };
              arreglo.push(objeto);
            });

            dispatch({
              type: UPDATE_RAYOS,
              rayos: listaRayos,
              localidades: arreglo,
            });
          }
        })
        .catch((e) => {
          dispatch({
            type: ERROR_RAYOS,
            error: "Error al realizar la petición de obtener rayos",
          });
        });
    } catch (error) {}
  };
};

export const VACIAR_TAREAS_RAYO = "VACIAR_TAREAS_RAYO";
export const vaciarTareasRayo = () => {
  return async (dispatch) => {
    dispatch({
      type: VACIAR_TAREAS_RAYO,
      tareas: null,
    });
  };
};

export const OBTENER_TAREAS_RAYO = "OBTENER_TAREAS_RAYO";
export const obtenerTareasRayo = (id) => {
  return async (dispatch) => {
    try {
      await url.api
        .get("/Backlogs/backlog_by_rayo/" + id + "/")
        .then((response) => {
          switch (response.status) {
            case 200:
              if (response.data.code === 404) {
                dispatch({
                  type: OBTENER_TAREAS_RAYO,
                  tareas: [],
                });
              } else {
                let newTareas = [];
                if (response.data.tasks_backlog.length !== 0) {
                  let tareas = response.data.tasks;
                  tareas.forEach((task) => {
                    task.task.selected = false;
                    task.task.optimized = null;
                    task.task.number = 0;
                    newTareas.push(task.task);
                  });
                }

                dispatch({
                  type: OBTENER_TAREAS_RAYO,
                  tareas: newTareas,
                });
              }

              break;
            default:
              dispatch({
                type: ERROR_RAYOS,
                error:
                  "Error " +
                  response.status +
                  " al realizar la petición de obtener tareas de rayo seleccionado",
              });
              break;
          }
        })
        .catch((e) => {
          dispatch({
            type: ERROR_RAYOS,
            error:
              "Error al realizar la petición de obtener tareas de rayo seleccionado",
          });
        });
      // await axios({
      //   method: "get",
      //   url: url.host + "/Backlogs/backlog_by_rayo/" + id + "/",
      //   headers: [],
      // })
      //   .then((response) => {
      //     console.log(response.data);
      //     switch (response.status) {
      //       case 200:
      //         if (response.data.code === 404) {
      //           dispatch({
      //             type: OBTENER_TAREAS_RAYO,
      //             tareas: [],
      //           });
      //         } else {
      //           let newTareas = [];
      //           if (response.data.tasks_backlog.length !== 0) {
      //             let tareas = response.data.tasks;
      //             tareas.forEach((task) => {
      //               task.task.selected = false;
      //               task.task.optimized = null;
      //               task.task.number = 0;
      //               newTareas.push(task.task);
      //             });
      //           }

      //           dispatch({
      //             type: OBTENER_TAREAS_RAYO,
      //             tareas: newTareas,
      //           });
      //         }

      //         break;
      //       default:
      //         dispatch({
      //           type: ERROR_RAYOS,
      //           error:
      //             "Error " +
      //             response.status +
      //             " al realizar la petición de obtener tareas de rayo seleccionado",
      //         });
      //         break;
      //     }
      //   })
      //   .catch((e) => {
      //     console.log(e.response);
      //     console.log(e.message)
      //     dispatch({
      //       type: ERROR_RAYOS,
      //       error:
      //         "Error al realizar la petición de obtener tareas de rayo seleccionado",
      //     });
      //   });
    } catch (e) {
      dispatch({
        type: ERROR_RAYOS,
        error:
          "Error al realizar la petición de obtener tareas de rayo seleccionado",
      });
    }
  };
};

export const MODIFICAR_TAREAS_RAYO = "MODIFICAR_TAREAS_RAYO";
export const modificarTareasRayo = (tareas, seleccionados) => {
  return async (dispatch) => {
    let estado = false;
    let contador = 0;
    let wayPoints = [];
    let orderWayPoints = [];
    let otherPoints = [];
    let contadorTareas = 0;

    tareas.forEach((tarea) => {
      seleccionados.forEach((seleccionado) => {
        if (tarea._id === seleccionado) {
          tarea.selected = !tarea.selected;
        }

        if (tarea.selected === true) {
          tarea.number = contadorTareas + 1;
          contadorTareas = contadorTareas + 1;
          estado = true;
        }
      });

      if (tarea.selected === true) {
        contador = contador + 1;
        wayPoints.push({
          location: { lat: tarea.latitude_task, lng: tarea.longitude_task },
          _id: tarea._id,
        });
        orderWayPoints.push(tarea);
      } else if (tarea.selected === false) {
        otherPoints.push(tarea);
      }
    });

    dispatch({
      type: MODIFICAR_TAREAS_RAYO,
      tareas: tareas,
      seleccionadoT: estado,
      wayPoints: wayPoints,
      contadorSeleccionado: contador,
      orderWayPoints: orderWayPoints,
      otherPoints: otherPoints,
    });
  };
};

export const DES_SELECCIONAR_TAREAS = "DES_SELECCIONAR_TAREAS";
export const desseleccionarTareas = (tareas) => {
  return async (dispatch) => {
    let estado = false;
    let otherPoints = [];
    tareas.forEach((tarea) => {
      tarea.selected = false;
      tarea.number = 0;
    });

    dispatch({
      type: DES_SELECCIONAR_TAREAS,
      tareas: tareas,
      seleccionadoT: estado,
      wayPoints: [],
      contadorSeleccionado: 0,
      orderWayPoints: [],
      otherPoints: otherPoints,
    });
  };
};

export const SELECCIONAR_TODAS_TAREAS = "SELECCIONAR_TODAS_TAREAS";
export const seleccionarTodasTareas = (tareas) => {
  return async (dispatch) => {
    let estado = true;
    let wayPoints = [];
    let orderWayPoints = [];
    let otherPoints = [];
    let contador = 0;
    let contadorTareas = 0;

    tareas.forEach((tarea) => {
      tarea.selected = true;
      contador = contador + 1;
      tarea.number = contadorTareas + 1;
      contadorTareas = contadorTareas + 1;
      wayPoints.push({
        location: { lat: tarea.latitude_task, lng: tarea.longitude_task },
        _id: tarea._id,
      });
      orderWayPoints.push(tarea);
    });

    dispatch({
      type: SELECCIONAR_TODAS_TAREAS,
      tareas: tareas,
      seleccionadoT: estado,
      wayPoints: wayPoints,
      orderWayPoints: orderWayPoints,
      contadorSeleccionado: contador,
      otherPoints: otherPoints,
    });
  };
};

export const REORDER_TAREAS = "REORDER_TAREAS";
export const reorderTareas = (tareas) => {
  return async (dispatch) => {
    let wayPoints = [];
    let orderWayPoints = [];
    let otherPoints = [];
    let contadorTareas = 0;

    tareas.forEach((tarea) => {
      if (tarea.selected === true) {
        tarea.number = contadorTareas + 1;
        contadorTareas = contadorTareas + 1;
        wayPoints.push({
          location: { lat: tarea.latitude_task, lng: tarea.longitude_task },
          _id: tarea._id,
        });
        orderWayPoints.push(tarea);
      } else if (tarea.selected === false) {
        otherPoints.push(tarea);
      }
    });

    dispatch({
      type: REORDER_TAREAS,
      tareas: tareas,
      wayPoints: wayPoints,
      orderWayPoints: orderWayPoints,
      otherPoints: otherPoints,
    });
  };
};

export const REORDER_GOOGLE = "REORDER_GOOGLE";
export const reorderGoogle = (tareas, wayPoints) => {
  return async (dispatch) => {
    dispatch({
      type: REORDER_GOOGLE,
      tareas: tareas,
      wayPoints: wayPoints,
    });
  };
};

export const TASKS_OPTIMIZATION = "TASKS_OPTIMIZATION";
export const tasksOptimization = (rayo, tareas) => {
  return async (dispatch) => {
    try {
      let infoTareas = [];
      let tareasSeleccionadas = [];
      let tareasNoSeleccionadas = [];
      let contadorTareas = 0;

      tareas.forEach((element) => {
        if (element.selected) {
          tareasSeleccionadas.push(element);
          infoTareas.push({
            _id: element._id,
            latitude_task: element.latitude_task,
            longitude_task: element.longitude_task,
          });
        } else {
          element.optimized = false;
          tareasNoSeleccionadas.push(element);
        }
      });

      let data = {
        tasks: infoTareas,
        rayos: [
          {
            _id: rayo._id,
            latitude_rayo: rayo.latitude_rayo,
            longitude_rayo: rayo.longitude_rayo,
          },
        ],
      };

      await fetch(url.host + "/Tasks/tasks_optimization_save/", {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.error) {
            const idError = res.error.detail.error_message.id;

            if (idError) {
              let tareasOrdenadas = tareasSeleccionadas.concat(
                tareasNoSeleccionadas
              );
              const i = tareasOrdenadas.findIndex(
                (element) => element._id === idError
              );
              let wayPoints = [];

              if (i >= 0) {
                tareasOrdenadas[i].optimized = false;
              }

              tareasOrdenadas.forEach((element) => {
                wayPoints.push({
                  location: {
                    lat: element.latitude_task,
                    lng: element.longitude_task,
                  },
                  _id: element._id,
                });
              });

              dispatch({
                type: TASKS_OPTIMIZATION,
                tareas: tareasOrdenadas,
                wayPoints: wayPoints,
              });
            }
            Utils.handleAlert(
              "Error en ruta",
              "Se ha encontrado una ruta que no se puede optimizar, saquela del Backlog e intente nuevamente",
              "Ok"
            );
          } else {
            if (res.rutas.length !== 0) {
              if (res.rutas !== undefined) {
                let newOrder = res.rutas[0].optimizacion;
                let tasksOrder = [];
                let wayPoints = [];

                newOrder.forEach((order) => {
                  tareasSeleccionadas.forEach((task) => {
                    if (order.task_id === task._id) {
                      wayPoints.push({
                        location: {
                          lat: task.latitude_task,
                          lng: task.longitude_task,
                        },
                        _id: task._id,
                      });
                      if (task.selected === true) {
                        task.number = contadorTareas + 1;
                        contadorTareas = contadorTareas + 1;
                        task.optimized = true;
                      } else {
                        task.optimized = false;
                      }
                      tasksOrder.push(task);
                    }
                  });
                });

                let newArrayOrder = tasksOrder.concat(tareasNoSeleccionadas);
                Utils.handleAlert(
                  "Ruta optimizada",
                  "Se ha optimizado correctamente la ruta",
                  "Ok"
                );

                dispatch({
                  type: TASKS_OPTIMIZATION,
                  tareas: newArrayOrder,
                  wayPoints: wayPoints,
                });
              } else {
                Utils.handleAlert(
                  "No se logro optimizar las rutas",
                  "Por favor contactarse con el área de TI",
                  "Ok"
                );
                dispatch({
                  type: TASKS_OPTIMIZATION,
                  tareas: [],
                  wayPoints: [],
                });
              }
            } else {
              Utils.handleAlert(
                "Sin rutas",
                "No se ha encontrado una ruta optima para las tareas ingresadas",
                "Ok"
              );
              dispatch({
                type: ERROR_RAYOS,
                error: 500,
              });
            }
          }
        });
    } catch (error) {
      Utils.handleAlert("Optimización", error, "Ok");
      dispatch({
        type: ERROR_RAYOS,
        error: error,
      });
    }
  };
};
