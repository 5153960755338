import React from "react";
import "./Tracking.css";
import moment from "moment";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import "../../css/global.css";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  DirectionsRenderer,
} from "react-google-maps";
import {
  LIGHT_MODE_GOOGLE,
  APPCONFIG,
  SERVICE_NAME,
} from "../../assets/utils/Helpers";
import {
  ID_ASIGNADO,
  ID_ACEPTADO,
  ID_INICIADO,
  ID_EN_PROGRESO,
} from "../../assets/utils/Helpers";
import CircularProgress from "@material-ui/core/CircularProgress";

import * as actionEstados from "../../store/actions/Estados";

import url from "../../config";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Utils from "../../assets/utils/Utils";
import LogoRayo from "../../assets/icons/logo-rayo-white.svg";
import UsuarioImagen from "../../assets/icons/icono-usuario-square.png";
import ImgRayo from "../../assets/icons/logo-rayo-blue-2.png";

import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
// import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import * as Realm from "realm-web";
const BSON = require("bson");

const icon = {
  url:
    "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
  scaledSize: new window.google.maps.Size(20, 20),
  origin: new window.google.maps.Point(0, 0),
};
const icon1 = {
  url:
    "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-p.png",
  scaledSize: new window.google.maps.Size(23, 30),
  origin: new window.google.maps.Point(0, 0),
};
const icon2 = {
  url:
    "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-d.png",
  scaledSize: new window.google.maps.Size(23, 30),
  origin: new window.google.maps.Point(0, 0),
};
const icon3 = {
  url:
    "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-c.png",
  scaledSize: new window.google.maps.Size(23, 30),
  origin: new window.google.maps.Point(0, 0),
};

const WrappedMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={props.defaultCenter}
      center={props.defaultCenter}
      options={{
        styles: LIGHT_MODE_GOOGLE,
      }}
    >
      {props.directions.length !== 0 ? (
        <DirectionsRenderer
          directions={props.directions}
          options={{ suppressMarkers: true }}
        />
      ) : (
        <></>
      )}

      {props.markers == null
        ? false
        : props.markers.map((position, index) => {
            return (
              props.isMarkerShown && (
                <Marker
                  icon={position.icon}
                  key={position.placeName + index}
                  position={{
                    lat: position.position.lat,
                    lng: position.position.lng,
                  }}
                ></Marker>
              )
            );
          })}

      {props.rayo === null
        ? false
        : props.isMarkerShown && (
            <Marker
              icon={props.rayo.icon}
              key={props.rayo.placeName}
              position={{
                lat: props.rayo.position.lat,
                lng: props.rayo.position.lng,
              }}
            ></Marker>
          )}
    </GoogleMap>
  ))
);

class Tracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      tipo: this.props.match.params.tipo,
      grupoTareas: null,
      wayPoints: [],
      rayoPosition: null,
      rayo: null,
      cliente: null,
      markers: null,
      tiempo: "",
      tipoTareas: [
        { _id: 1, nombre: "Pick up", color: "#f7ac06" },
        { _id: 2, nombre: "Delivery", color: "#f25c22" },
        { _id: 3, nombre: "Cita", color: "#1b84bf" },
      ],
      fecha: "",

      //testing
      directions: [],
      origin: 0,
      defaultCenter: { lat: -33.4473526, lng: -70.6379711 },
      mostrarRayoMapa: false,
      showWhatsapp: false,
    };
  }

  componentDidMount() {
    this.obtenerEstados();
    if (this.state.tipo === "1") this.obtenerGrupoTareas();
    else this.obtenerTarea();

    //this.watchRayos();
  }

  relistening = (rayo_id) => {
    if (rayo_id) {
      const app = new Realm.App(APPCONFIG);

      app.logIn(Realm.Credentials.anonymous()).then((user) => {
        const db = app.currentUser
          .mongoClient(SERVICE_NAME)
          .db("fleet_manager");

        let options = {
          filter: {
            operationType: "update",
            "documentKey._id": new BSON.ObjectId(rayo_id),
            $or: [
              {
                "updateDescription.updatedFields.status_rayo": {
                  $exists: true,
                },
              },
              {
                "updateDescription.updatedFields.latitude_rayo": {
                  $exists: true,
                },
              },
              {
                "updateDescription.updatedFields.longitude_rayo": {
                  $exists: true,
                },
              },
            ],
          },
        };

        const watch = db.collection("rayos").watch(options);
        let contexto = this;
        nextUpdate();

        function nextUpdate() {
          watch.next().then((result) => {
            let rayo = result.value.fullDocument;
            rayo._id = new BSON.ObjectId(result.value.fullDocument._id);
            contexto.cargarRuta(
              contexto.state.wayPoints,
              result.value.fullDocument,
              contexto.state.mostrarRayoMapa,
              false
            );

            nextUpdate();
          });
        }
      });
    }
  };

  watchRayos(db, options) {
    const app = new Realm.App(APPCONFIG);

    app.logIn(Realm.Credentials.anonymous()).then((user) => {
      const db = app.currentUser.mongoClient("Cluster0").db("fleet_manager");

      let options = {
        filter: {
          operationType: "update",
          $or: [
            {
              "updateDescription.updatedFields.latitude_rayo": {
                $exists: true,
              },
            },
            {
              "updateDescription.updatedFields.longitude_rayo": {
                $exists: true,
              },
            },
          ],
        },
      };

      const watch = db.collection("rayos").watch(options);
      this.nextUpdate(watch);
    });
  }

  nextUpdate = (watch) => {
    watch.next().then((result) => {
      try {
        let string = "";
        result.value.documentKey._id.id.forEach((element) => {
          string = string + element.toString();
        });
        let objectID = new BSON.ObjectId(result.value.documentKey._id);
        let stringObject = objectID.toString();
        let rayo = cloneDeep(result.value.fullDocument);
        rayo.id = stringObject;

        if (this.state.rayo != null) {
          if (this.state.rayo.id === stringObject) {
            try {
              this.setState({
                rayo: rayo,
              });
            } catch (err) {
              Utils.handleAlert(
                "Actualizar data",
                "Se ha realizado un cambio, pero ha ocurrido un error al momento de mostrarlo, recargue la página para visualizarlo",
                "Ok"
              );
            }
          }
        }

        this.nextUpdate(watch);
      } catch (error) {}
    });
  };

  obtenerEstados = async () => {
    try {
      const { dispatch } = this.props;
      const obtenerEstados = actionEstados.obtenerEstados();
      dispatch(obtenerEstados);
    } catch (error) {
      Utils.handleAlert(
        "No se ha encontrado el Tracking",
        "La URL no pertenece a ningún Tracking registrado, intente nuevamente",
        "Ok"
      );
    }
  };

  obtenerRayo = async (id, posicionRayo, actualizar) => {
    try {
      let res = await url.api.get("/Rayos/rayo/" + id + "/");
      if (res.status === 200) {
        let rayo = res.data;
        let objeto;
        let wayPoints = cloneDeep(this.state.wayPoints);

        if (posicionRayo) {
          objeto = {
            icon: icon,
            position: {
              lat: parseFloat(rayo.location.coordinates[0]),
              lng: parseFloat(rayo.location.coordinates[1]),
            },
            map: null,
            title: "RAYO",
            placeName: rayo._id,
          };
          wayPoints = [
            { location: { lat: rayo.location.coordinates[0], lng: rayo.location.coordinates[1] } },
          ].concat(wayPoints);
        } else {
          objeto = null;
        }

        if (actualizar) {
          this.setState({
            rayo: rayo,
            rayoPosition: objeto,
            wayPoints: wayPoints,
          });
          return rayo;
        } else {
          this.setState({ rayoPosition: objeto, wayPoints: wayPoints });
          return rayo;
        }
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "No ha sido posible cargar la data del Rayo asignado",
          "Ok"
        );
        return false;
      }
    } catch (error) {
      Utils.handleAlert(
        "Error no controlado",
        "No ha sido posible cargar la data del Rayo asignado",
        "Ok"
      );
      return false;
    }
  };

  mensaje = (estado) => {
    switch (estado) {
      case "5f05f3d37f5164494c74b967": // ASIGNADO modal recepcionado
      case "5f05f3dc7f5164494c74b968": // ACEPTADO
        Utils.handleAlert("Recepcionado", "Envío recepcionado por Rayo", "Ok");
        return false;
      case "5f05f3eb7f5164494c74b969": // INICIADO modal en entrega
      case "5f05f3f77f5164494c74b96a": // EN Progreso
        Utils.handleAlert(
          "En tránsito",
          "El envío se encuentra en transito al destino",
          "Ok"
        );
        return true;
      case "5f05f4077f5164494c74b96b": // EXITOSO modal entregado
        Utils.handleAlert(
          "Entregado",
          "Envío entregado exitosamente al destinatario",
          "Ok"
        );
        return false;
      case "5f05f4217f5164494c74b96d": // RECHAZADO modal entrega cancelada
      case "5f05f42e7f5164494c74b96e": // CANCELADO
        Utils.handleAlert("Cancelado", "El envío a sido cancelado", "Ok");
        return false;
      case "5f05f4147f5164494c74b96c": // FALLIDO modal programacion nuevo intento
        Utils.handleAlert(
          "Fallido",
          "El envío esta siendo gestionado por Rayo",
          "Ok"
        );
        return false;
      default:
        // NO ASIGNADO no modal
        return false;
    }
  };

  mensajeHoraProgramada = (estado) => {
    switch (estado) {
      case "5f05f3d37f5164494c74b967": // ASIGNADO modal recepcionado
      case "5f05f3dc7f5164494c74b968": // ACEPTADO
        return "Envío recepcionado por Rayo";
      case "5f05f3eb7f5164494c74b969": // INICIADO modal en entrega
      case "5f05f3f77f5164494c74b96a": // EN Progreso
        return "El envío se encuentra en transito al destino";
      case "5f05f4077f5164494c74b96b": // EXITOSO modal entregado
        return "Envío entregado exitosamente al destinatario";
      case "5f05f4217f5164494c74b96d": // RECHAZADO modal entrega cancelada
      case "5f05f42e7f5164494c74b96e": // CANCELADO
        return "El envío a sido cancelado";
      case "5f05f4147f5164494c74b96c": // FALLIDO modal programacion nuevo intento
        return "El envío esta siendo gestionado por Rayo";
      default:
        // NO ASIGNADO no modal
        return "A la espera de un Rayo disponible para recibir el pedido";
    }
  };

  mostrarTiempo = (estado) => {
    switch (estado) {
      case "5f05f3d37f5164494c74b967": // ASIGNADO modal recepcionado
      case "5f05f3dc7f5164494c74b968": // ACEPTADO
        return "programada";
      case "5f05f3eb7f5164494c74b969": // INICIADO modal en entrega
      case "5f05f3f77f5164494c74b96a": // EN Progreso
        //return 'estimada';
        return "programada";
      case "5f05f4077f5164494c74b96b": // EXITOSO modal entregado
      case "5f05f4217f5164494c74b96d": // RECHAZADO modal entrega cancelada
      case "5f05f42e7f5164494c74b96e": // CANCELADO
      case "5f05f4147f5164494c74b96c": // FALLIDO modal programacion nuevo intento
        return "nan";
      default:
        // NO ASIGNADO no modal
        return "programada";
    }
  };

  //funcion encargada de returno true o false para mostrar el rayo en el mapa
  //es necesario que se le pase el estado para verificar si se debe mostrar o no en el mapa
  mostrarRayoEnMapa = (estado) => {
    switch (estado) {
      case "5f05f3d37f5164494c74b967": // ASIGNADO modal recepcionado
      case "5f05f3dc7f5164494c74b968": // ACEPTADO
        return false;
      case "5f05f3eb7f5164494c74b969": // INICIADO modal en entrega
      case "5f05f3f77f5164494c74b96a": // EN Progreso
        //return 'estimada';
        return true;
      case "5f05f4077f5164494c74b96b": // EXITOSO modal entregado
      case "5f05f4217f5164494c74b96d": // RECHAZADO modal entrega cancelada
      case "5f05f42e7f5164494c74b96e": // CANCELADO
      case "5f05f4147f5164494c74b96c": // FALLIDO modal programacion nuevo intento
        return false;
      default:
        // NO ASIGNADO no modal
        return false;
    }
  };

  obtenerTarea = async () => {
    try {
      let res = await url.api.get("/Tasks/task/" + this.state.id + "/");
      if (res.status === 200) {
        if (typeof res.data.code === "undefined") {
          const tarea = res.data;
          let cliente = tarea.customer;
          let wayPoints = [];
          let markers = [];
          wayPoints.push({
            location: { lat: tarea.latitude_task, lng: tarea.longitude_task },
          });

          let objeto = {
            icon: icon1,
            position: {
              lat: parseFloat(tarea.latitude_task),
              lng: parseFloat(tarea.longitude_task),
            },
            map: null,
            title: tarea._id,
            placeName: tarea._id,
          };

          if (tarea.type_task === 2) {
            objeto.icon = icon2;
          } else if (tarea.type_task === 3) {
            objeto.icon = icon3;
          }

          markers.push(objeto);

          let posicionRayo = this.mensaje(tarea.idstatus_task);

          let rayoEncontrado = false;

          if (tarea.idrayo_task !== null) {
            rayoEncontrado = await this.obtenerRayo(
              tarea.idrayo_task,
              posicionRayo,
              true
            );
          }

          if (this.mostrarTiempo(tarea.idstatus_task) === "programada") {
            this.setState({
              fecha: moment(tarea.date_task).utcOffset("+00").format("HH:mm"),
            });
          }

          if (rayoEncontrado !== false) {
            this.relistening(rayoEncontrado._id);
          }

          let showWhatsapp = false;
          let hora = parseInt(moment(new Date()).format("HH"));

          if (
            (tarea.idstatus_task === ID_ASIGNADO ||
              tarea.idstatus_task === ID_ACEPTADO ||
              tarea.idstatus_task === ID_EN_PROGRESO ||
              tarea.idstatus_task === ID_INICIADO) &&
            hora > 8 &&
            hora < 23
          ) {
            showWhatsapp = true;
          }

          this.cargarRuta(
            wayPoints,
            rayoEncontrado,
            this.mostrarRayoEnMapa(res.data.idstatus_task),
            true
          );
          this.setState({
            grupoTareas: {
              idrayo_taskgroup: tarea.idrayo_task,
              tasks: [tarea],
              idstatus_taskgroup: tarea.idstatus_task,
              orden_id_taskgroup: tarea.order_id_task,
            },
            showWhatsapp: showWhatsapp,
            wayPoints: wayPoints,
            cliente: cliente,
            markers: markers,
            tiempo: this.mostrarTiempo(res.data.idstatus_task),
            mostrarRayoMapa: this.mostrarRayoEnMapa(res.data.idstatus_task),
          });
        } else {
          Utils.handleAlert(
            "No se ha encontrado el Tracking",
            "La URL no pertenece a ningún Tracking registrado, intente nuevamente",
            "Ok"
          );
        }
      } else {
        Utils.handleAlert(
          "No se ha encontrado el Tracking",
          "La URL no pertenece a ningún Tracking registrado, intente nuevamente",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "No se ha encontrado el Tracking",
        "La URL no pertenece a ningún Tracking registrado, intente nuevamente",
        "Ok"
      );
    }
  };

  obtenerGrupoTareas = async () => {
    try {
      let res = await url.api.get(
        "/Task-Groups/tasks_group/" + this.state.id + "/"
      );
      if (res.status === 200) {
        const tareas = res.data.tasks;
        let cliente = res.data.customer;
        let wayPoints = [];
        let markers = [];

        tareas.forEach((tarea) => {
          wayPoints.push({
            location: { lat: tarea.latitude_task, lng: tarea.longitude_task },
          });
          let objeto = {
            icon: icon1,
            position: {
              lat: parseFloat(tarea.latitude_task),
              lng: parseFloat(tarea.longitude_task),
            },
            map: null,
            title: tarea._id,
            placeName: tarea._id,
          };

          if (tarea.type_task === 2) {
            objeto.icon = icon2;
          } else if (tarea.type_task === 3) {
            objeto.icon = icon3;
          }

          markers.push(objeto);
        });

        let posicionRayo = this.mensaje(res.data.idstatus_taskgroup);

        let rayoEncontrado = false;

        if (res.data.idrayo_taskgroup !== null) {
          rayoEncontrado = await this.obtenerRayo(
            res.data.idrayo_taskgroup,
            posicionRayo,
            true
          );
        }

        if (this.mostrarTiempo(res.data.idstatus_taskgroup) === "programada") {
          if (res.data.tasks.length !== 0) {
            this.setState({
              fecha: moment(res.data.tasks[res.data.tasks.length - 1].date_task)
                .utcOffset("+00")
                .format("HH:mm"),
            });
          } else {
            this.setState({
              fecha: moment(res.data.start_date_taskgroup)
                .utcOffset("+00")
                .format("HH:mm"),
            });
          }
        }

        if (
          rayoEncontrado !== false &&
          this.mostrarRayoEnMapa(res.data.idstatus_taskgroup)
        ) {
          this.relistening(rayoEncontrado._id);
        }

        this.cargarRuta(
          wayPoints,
          rayoEncontrado,
          this.mostrarRayoEnMapa(res.data.idstatus_taskgroup),
          true
        );

        let showWhatsapp = false;
        let hora = parseInt(moment(new Date()).format("HH"));
        if (
          (res.data.idstatus_taskgroup === ID_ASIGNADO ||
            res.data.idstatus_taskgroup === ID_ACEPTADO ||
            res.data.idstatus_taskgroup === ID_EN_PROGRESO ||
            res.data.idstatus_taskgroup === ID_INICIADO) &&
          hora > 8 &&
          hora < 23
        ) {
          showWhatsapp = true;
        }

        this.setState({
          grupoTareas: res.data,
          wayPoints: wayPoints,
          cliente: cliente,
          markers: markers,
          showWhatsapp: showWhatsapp,
          tiempo: this.mostrarTiempo(res.data.idstatus_taskgroup),
          mostrarRayoMapa: this.mostrarRayoEnMapa(res.data.idstatus_taskgroup),
        });
      } else {
        Utils.handleAlert(
          "No se ha encontrado el Tracking",
          "La URL no pertenece a ningún Tracking registrado, intente nuevamente",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "No se ha encontrado el Tracking",
        "La URL no pertenece a ningún Tracking registrado, intente nuevamente",
        "Ok"
      );
    }
  };

  cargarRuta = (wayPoints, rayoEncontrado, mostrarRayo, primeraCarga) => {
    try {
      this.setState({
        directions: [],
        // origin: origin,
        // defaultCenter: origin,
      });

      //console.log(mostrarRayo);
      if (rayoEncontrado !== false && mostrarRayo === true) {
        let ubicacionRayo = {
          location: {
            lat: rayoEncontrado.latitude_rayo,
            lng: rayoEncontrado.longitude_rayo,
          },
        };
        if (primeraCarga) {
          wayPoints.unshift(ubicacionRayo);
        } else {
          wayPoints[0] = ubicacionRayo;
        }

        let objeto = {
          icon: icon,
          position: {
            lat: parseFloat(rayoEncontrado.latitude_rayo),
            lng: parseFloat(rayoEncontrado.longitude_rayo),
          },
          map: null,
          title: "RAYO",
          placeName: rayoEncontrado._id,
        };
        this.setState({
          rayoPosition: objeto,
        });
      }

      let waypointsTest = wayPoints.map((p) => ({
        location: { lat: p.location.lat, lng: p.location.lng },
        stopover: true,
      }));

      let origin;
      let destination;

      // console.log('arreglo de waypoints');
      // console.log(waypointsTest);

      if (waypointsTest.length === 1) {
        origin = waypointsTest[0].location;
        this.setState({
          defaultCenter: origin,
        });
      } else {
        origin = waypointsTest.shift().location;
        destination = waypointsTest.pop().location;

        const directionsService = new window.google.maps.DirectionsService();
        const travelMode = window.google.maps.TravelMode.DRIVING;

        const statusOK = window.google.maps.DirectionsStatus.OK;
        directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: travelMode,
            waypoints: waypointsTest,
          },
          (result, status) => {
            if (status === statusOK) {
              this.setState({
                directions: result,
                origin: origin,
                defaultCenter: origin,
              });
            } else {
              Utils.handleAlert(
                "No se logro generar el Tracking",
                "No se logro mostrar ruta entre los puntos",
                "Ok"
              );
            }
          }
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "No se logro generar el Tracking",
        "Error no controlado al generar ruta",
        "Ok"
      );
    }
  };

  returnLinkSeguimiento = (task) => {
    if (this.state.cliente && this.state.cliente.length !== 0) {
      let nameCustomer = "";
      this.state.cliente.forEach((element) => {
        nameCustomer = element.name_customer;
      });

      let newName = Utils.nameCustomerSeguimiento(nameCustomer);
      let link =
        "https://seguimiento.thor.rayoapp.com/" +
        newName +
        "/?id=" +
        this.state.grupoTareas.orden_id_taskgroup;
      return link;
    } else {
      Utils.handleAlert(
        "Link de seguimiento",
        "Esta tarea no tiene la información suficiente para generar el link de seguimiento, contactenos para más información",
        "Ok"
      );
    }
  };

  render() {
    let estados = this.props.estados.estados;
    let rayo = this.state.rayo;

    const Estados = ({ id }) => {
      if (estados === null) {
        return false;
      } else {
        return estados.map((estado, index) => {
          if (estado._id === id) {
            let color = "";
            switch (estado.number_status) {
              case 1:
                color = "txt-no-asignado";
                break;
              case 2:
                color = "color-orange";
                break;
              case 3:
                color = "txt-aceptado";
                break;
              case 4:
                color = "color-blue";
                break;
              case 5:
                color = "txt-en-progreso";
                break;
              case 6:
                color = "txt-exitoso";
                break;
              case 7:
                color = "txt-ha-fallado";
                break;
              case 8:
                color = "txt-rechazado";
                break;
              default:
                color = "txt-cancelado";
                break;
            }
            return (
              <label className={color + " txt-bold"} key={index}>
                {estado.name_status}
              </label>
            );
          } else {
            return false;
          }
        });
      }
    };

    const Tipo = ({ id }) => {
      return this.state.tipoTareas.map((tipo, index) => {
        if (tipo._id === id) {
          return (
            <label
              className="txt-bold"
              key={index}
              style={{ color: tipo.color }}
            >
              {tipo.nombre}
            </label>
          );
        } else {
          return false;
        }
      });
    };

    const Tareas = ({ tarea, tareas, index }) => {
      let borde = "border-bottom border-container-informacion pb-2 mb-2";
      if (tareas.length - 1 === index) {
        borde = "";
      }

      return (
        <div className={"row m-0 " + borde}>
          <div className="col-6 col-md-2 pl-0">
            <Estados id={tarea.idstatus_task} />
          </div>
          <div className="col-6 col-md-2 pl-0">
            <Tipo id={tarea.type_task} />
          </div>
          <div className="col-md-2 pl-0">{tarea.contact_name_task}</div>
          <div className="col-md-2 pl-0">
            {moment(tarea.date_task)
              .utcOffset("+00")
              .format("DD-MM-YYYY HH:mm")}
          </div>
          <div className="col-md-4 px-0">{tarea.address_task}</div>
        </div>
      );
    };

    const Rayo = () => {
      if (rayo === null) {
        return (
          <div className="col-md-4 row m-0 tracking-rayo d-flex justify-content-center align-items-center">
            <CircularProgress />
          </div>
        );
      } else {
        return (
          <div className="col-md-4 row m-0 tracking-rayo">
            <div className="col-4 d-flex justify-content-start align-items-center">
              <div className="img-tracking p-relative">
                <img
                  src={rayo.image_rayo}
                  className="img-tracking"
                  alt="rayo"
                />
              </div>
            </div>
            <div className="col-8 pl-0 d-flex justify-content-start align-items-center">
              <div className="w-100">
                <p>Persona a cargo de la entrega:</p>
                <p className="mb-1">{rayo.name_rayo}</p>
                {/* {this.state.showWhatsapp ? (
                  <Button
                    size="small"
                    variant="contained"
                    className=""
                    onClick={() => {
                      window.open(
                        "https://api.whatsapp.com/send/?phone=" +
                          rayo.number_rayo +
                          "&text=Hola%2C+quiero+saber+sobre+mi+pedido+" +
                          this.state.grupoTareas.orden_id_taskgroup +
                          "&app_absent=0",
                        "_blank"
                      );
                    }}
                    style={{ backgroundColor: "#25D366", color: "#ffffff" }}
                    startIcon={
                      <WhatsAppIcon className="text-white" fontSize="small" />
                    }
                  >
                    WhatsApp
                  </Button>
                ) : (
                  false
                )} */}
                <a href={"" + this.returnLinkSeguimiento()} target="_blank" rel="noopener noreferrer">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={
                      <LibraryBooksIcon
                        className="text-white"
                        fontSize="small"
                      />
                    }
                  >
                    Seguimiento
                  </Button>
                </a>
              </div>
            </div>
          </div>
        );
      }
    };

    const SinRayo = () => {
      return (
        <div className="col-md-4 row m-0 tracking-rayo">
          <div className="col-4 d-flex justify-content-start align-items-center">
            <div className="w-100 img-tracking">
              <img src={UsuarioImagen} className="img-tracking" alt="rayo" />
              <div className="position-absolute dot activo estado-track"></div>
            </div>
          </div>
          <div className="col-8 pl-0 d-flex justify-content-start align-items-center">
            <div className="w-100">
              <p>No se ha asignado un rayo</p>
              <p>A este grupo de tareas</p>
            </div>
          </div>
        </div>
      );
    };

    const Informacion = ({ grupoTareas }) => {
      return (
        <div>
          <Paper
            elevation={5}
            square={false}
            className="container-informacion p-2"
          >
            <div className="row m-0">
              {grupoTareas.idrayo_taskgroup === null ? <SinRayo /> : <Rayo />}
              <div className="col-md-8">
                <div className="container-gt">
                  <div className="row m-0">
                    <div className="col-6 col-md-2 pl-0">
                      <b>ORDER ID: </b>
                    </div>
                    <div className="col-6 col-md-2 pl-0">
                      {grupoTareas.orden_id_taskgroup}
                    </div>
                  </div>
                  <hr className="my-1" />
                  {grupoTareas.tasks.map((tarea, index) => (
                    <Tareas
                      tarea={tarea}
                      index={index}
                      tareas={grupoTareas.tasks}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Paper>
        </div>
      );
    };

    const Navbar = () => {
      return this.state.cliente.map((cliente, i) => (
        <Box display="flex" justifyContent="center" key={i}>
          <Box py={1} justifyContent="center" className="d-desktop-flex">
            <Typography variant="h5">
              <img
                alt="rayo"
                src={cliente.image_customer}
                className="rounded img-customer"
                style={{ paddingBottom: 3, marginRight: 10 }}
              />
              {cliente.name_customer + " by"}
              <img
                alt="rayo"
                src={ImgRayo}
                className="img-logo-rayo"
                style={{ paddingBottom: 3, marginLeft: 10 }}
              />
            </Typography>
          </Box>

          <Box py={1} className="d-mobile">
            <Typography variant="h5">
              <img
                alt="rayo"
                src={cliente.image_customer}
                className="rounded img-customer"
                style={{ paddingBottom: 3, marginRight: 10 }}
              />
              {cliente.name_customer}
            </Typography>
            <Typography variant="h5" align="center">
              by
              <img
                alt="rayo"
                src={ImgRayo}
                className="img-logo-rayo"
                style={{ paddingBottom: 3, marginLeft: 10 }}
              />
            </Typography>
          </Box>
        </Box>
      ));
    };

    const DefaultNavbar = () => {
      return (
        <div className="w-100 py-2 back-dark-grey d-flex justify-content-center">
          <img alt="rayo" src={LogoRayo} height="40" />
        </div>
      );
    };

    const TiempoLlegada = () => {
      return this.state.tiempo === "programada" ? (
        <div className="w-100 p-2 back-super-light-grey border-top border-bottom">
          <div className="w-100 d-flex justify-content-center">
            <div className="container-estimado">
              <Typography variant="body1" align="center">
                Hora estimada de entrega
              </Typography>
              <h3 className="mb-0 text-roboto-regular text-center">
                {this.state.fecha}
              </h3>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <div className="container-estimado">
              <Typography variant="body1" align="center">
                {this.state.grupoTareas === null
                  ? false
                  : this.mensajeHoraProgramada(
                      this.state.grupoTareas.idstatus_taskgroup
                    )}
              </Typography>
            </div>
          </div>
        </div>
      ) : this.state.tiempo === "estimada" ? (
        this.props.tracking.tiempo === null ? (
          false
        ) : (
          <div className="w-100 p-2 back-super-light-grey border-top border-bottom">
            <div className="w-100 d-flex justify-content-center">
              <div className="container-estimado">
                <Typography variant="body1" align="center">
                  Hora estimada de entrega
                </Typography>
                <h3 className="mb-0 text-roboto-regular pb-2">
                  {this.props.tracking.tiempo}
                </h3>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-center">
              <div className="container-estimado">
                <Typography variant="body1" align="center">
                  {this.state.grupoTareas === null
                    ? false
                    : this.mensajeHoraProgramada(
                        this.state.grupoTareas.idstatus_taskgroup
                      )}
                </Typography>
              </div>
            </div>
          </div>
        )
      ) : (
        false
      );
    };

    return (
      <div className="container-tracking">
        {this.state.cliente === null ? (
          false
        ) : this.state.cliente.length === 0 ? (
          <DefaultNavbar />
        ) : (
          <Navbar cliente={this.state.cliente} />
        )}
        {/* <div className="row m-0 d-flex justify-content-center p-1">
            <label className="mb-0 txt-carta-name">
              by <b className="text-blue">RAYO</b>
            </label>
          </div> */}

        <TiempoLlegada />

        <div
          className={
            this.state.tiempo === "programada"
              ? "container-mapa"
              : this.state.tiempo === "estimada" &&
                this.props.tracking.tiempo === null
              ? "container-mapa"
              : "container-mapa-time"
          }
        >
          <WrappedMap
            isMarkerShown
            defaultCenter={this.state.defaultCenter}
            directions={this.state.directions}
            wayPoints={this.state.wayPoints}
            markers={this.state.markers}
            rayo={this.state.rayoPosition}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyAukzvlrg866-YUl0tIrUGDI8lCf0MYaAg`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
          {this.state.grupoTareas === null ? (
            false
          ) : (
            <Informacion grupoTareas={this.state.grupoTareas} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    estados: store.estados,
    tracking: store.tracking,
  };
};

export default connect(mapStateToProps)(Tracking);
