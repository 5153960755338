import React from "react";
import $ from "jquery";

import PlacesAutocomplete from "react-places-autocomplete";
import { TextField } from "@material-ui/core";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import parse from "autosuggest-highlight/parse";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { ID_EXITOSO, ID_FALLIDO } from "../../utils/Helpers";

class FormularioTareas extends React.Component {
  render() {
    // const DetalleTemplate = ({ selected }) => {
    //     if (this.props.templatesStore === null || selected === '') {
    //         return <div></div>;
    //     } else {
    //         return this.props.templatesStore
    //             .filter((template) => template._id.toLowerCase().includes(selected.toLowerCase()))
    //             .map((item) =>
    //                 item.type_activity_template.map((actividad, i) => (
    //                     <div className="row m-0 px-0" key={i}>
    //                         <div className="col-md-1 pl-0">
    //                             <div className="square-template back-grey-input d-flex justify-content-center align-items-center">
    //                                 <img
    //                                     src={actividad.image_type_activity}
    //                                     height="25"
    //                                     className="mb-0"
    //                                     alt="icono"
    //                                 />
    //                             </div>
    //                             {i === 0 ? false : <div className="borde-plomo-act"></div>}
    //                         </div>
    //                         <div className="col-md-3 p-0">
    //                             <input
    //                                 disabled
    //                                 className="form-control template-input mb-2"
    //                                 placeholder="Nombre del campo"
    //                                 defaultValue={actividad.field}
    //                             />
    //                         </div>
    //                         <div className="col-md-8 pr-0 pl-1">
    //                             <input
    //                                 disabled
    //                                 className="form-control template-input mb-2"
    //                                 placeholder="Descripción"
    //                                 defaultValue={actividad.descriptionTypeActivity}
    //                             />
    //                         </div>
    //                     </div>
    //                 ))
    //             );
    //     }
    // };
    return (
      <div className="w-100 p-4 form-tareas">
        <div className="form-row">
          <div className="form-group col-md-6">
            <label className="texto-form-dark">Orden ID</label>
            <label className="texto-form-red"> *</label>
            <input
              required
              disabled
              className="form-control"
              placeholder="XG123456"
              name="order_id_task"
              value={this.props.orderID}
              onChange={(e) => this.props.change(e, this.props.index)}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="texto-form-dark">Inicio programado</label>
            <label className="texto-form-red"> *</label>
            <TextField
              required
              name="date_task"
              type="datetime-local"
              className="form-control"
              value={this.props.tarea.date_task}
              onChange={(e) => this.props.change(e, this.props.index)}
              InputLabelProps={{
                shrink: false,
              }}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label className="texto-form-dark mb-1">Nombre de contacto</label>
            <label className="texto-form-red"> *</label>
            <input
              required
              className="form-control"
              placeholder="Francisco Alonso Reyes Valle"
              name="contact_name_task"
              value={this.props.tarea.contact_name_task}
              onChange={(e) => this.props.change(e, this.props.index)}
            />
          </div>
          <div className="form-group col-md-6 task-form">
            <label className="texto-form-dark">Número de contacto</label>
            <label className="texto-form-red"> *</label>
            <PhoneInput
              preferredCountries={["cl", "mx"]}
              country={"cl"}
              inputProps={{
                name: "contact_phone_task",
                required: true,
                type: "text",
                minLength: 9,
              }}
              className="form-control"
              placeholder="989828918"
              name="contact_phone_task"
              value={this.props.tarea.contact_phone_task}
              onChange={(e) => this.props.changePhone(e, this.props.index)}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="texto-form-dark">Email</label>
          <input
            className="form-control"
            placeholder="Email de contacto"
            name="contact_email"
            value={this.props.tarea.contact_email}
            onChange={(e) => this.props.change(e, this.props.index)}
          />
        </div>

        <div className="form-group">
          <label className="texto-form-dark">Dirección</label>
          <label className="texto-form-red"> *</label>
          <PlacesAutocomplete
            value={this.props.tarea.address_task}
            searchOptions={{ componentRestrictions: { country: ["cl"] } }}
            onChange={(e) => this.props.changeAddress(e, this.props.index)}
            onSelect={(e) =>
              this.props.changeAddressSelect(e, this.props.index)
            }
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => {
              return (
                <Autocomplete
                  name="address_task"
                  options={suggestions}
                  getOptionLabel={(option) => option.description}
                  onChange={(e, value) => {
                    this.props.changeAddressSelect(value, this.props.index);
                  }}
                  value={{ description: this.props.tarea.address_task }}
                  onInputChange={(e, value) => {
                    this.props.changeAddress(value, this.props.index);
                  }}
                  getOptionSelected={(option, value) => {
                    return value.description;
                  }}
                  clearOnBlur={true}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        {...getInputProps({
                          name: "address_task",
                          className: "form-control w-100 px-2",
                          placeholder: "Compañia de Jesus #1249, Santiago*",
                          required: true,
                        })}
                      />
                    );
                  }}
                  required={true}
                  disableClearable={true}
                  noOptionsText={
                    loading ? "Cargando..." : "No ha seleccionado una opción"
                  }
                  renderOption={(option) => {
                    const matches = option.matchedSubstrings;
                    const parts = parse(
                      option.formattedSuggestion.mainText,
                      matches.map((match) => [
                        match.offset,
                        match.offset + match.length,
                      ])
                    );

                    return (
                      <Grid
                        container
                        alignItems="center"
                        className="autocomplete-creacion"
                      >
                        <Grid item>
                          <LocationOnIcon className="color-light-grey mr-2" />
                        </Grid>
                        <Grid item xs>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{ fontWeight: part.highlight ? 700 : 400 }}
                              className="MuiAutocomplete-option-title"
                            >
                              {part.text}
                            </span>
                          ))}

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            className="MuiAutocomplete-option-subtitle"
                          >
                            {option.formattedSuggestion.secondaryText}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                />
              );
            }}
          </PlacesAutocomplete>
        </div>
        <div className="form-group">
          <label className="texto-form-dark">Dirección 2</label>
          <input
            className="form-control"
            placeholder="Dirección 2"
            name="address2"
            value={this.props.tarea.address2}
            onChange={(e) => this.props.change(e, this.props.index)}
          />
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <label className="texto-form-dark">Descripción</label>
            <input
              className="form-control"
              placeholder="Descripción de la tarea"
              name="description_task"
              value={this.props.tarea.description_task}
              onChange={(e) => this.props.change(e, this.props.index)}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="texto-form-dark">Tags</label>

            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="tag-addon">
                  #
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Tag"
                aria-label="Tag"
                aria-describedby="tag-addon"
                name="tags"
                value={this.props.tarea.tags}
                onChange={(e) => this.props.change(e, this.props.index)}
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="texto-form-dark">
            Template de actividades exitoso
          </label>
          <label className="texto-form-red"> *</label>
          <select
            disabled={
              this.props.cliente == null ||
              this.props.cliente === 0 ||
              this.props.cliente === ""
                ? true
                : false
            }
            required
            className="custom-select mb-2"
            name="template_successful_task"
            onChange={(e) =>
              this.props.changeSelect(e, this.props.index, "successful")
            }
            value={this.props.tarea.template_successful_task.idTemplate}
          >
            <option value="">- Seleccionar Template -</option>
            {this.props.templates
              .filter((template) => {
                let bandera = false;
                template.customer_template.forEach((clientTemplate) => {
                  if (clientTemplate.idCustomer.includes(this.props.cliente)) {
                    bandera = true;
                    return;
                  }
                });
                return bandera;
              })
              .map((templa, index) => (
                <option value={templa._id} key={index}>
                  {templa.code_template}
                </option>
              ))}
          </select>
          {/* <DetalleTemplate selected={this.props.tarea.template_successful_task.idTemplate} /> */}
        </div>

        <div className="form-group">
          <label className="texto-form-dark">
            Template de actividades fallido
          </label>
          <label className="texto-form-red"> *</label>
          <select
            disabled={
              this.props.cliente == null ||
              this.props.cliente === 0 ||
              this.props.cliente === ""
                ? true
                : false
            }
            required
            className="custom-select mb-2"
            name="template_failed_task"
            onChange={(e) =>
              this.props.changeSelect(e, this.props.index, "failed")
            }
            value={this.props.tarea.template_failed_task.idTemplate}
          >
            <option value="">- Seleccionar Template -</option>
            {this.props.templates
              .filter((template) => {
                let bandera = false;
                template.customer_template.forEach((clientTemplate) => {
                  if (clientTemplate.idCustomer.includes(this.props.cliente)) {
                    bandera = true;
                    return;
                  }
                });
                return bandera;
              })
              .map((templa, index) => (
                <option value={templa._id} key={index}>
                  {templa.code_template}
                </option>
              ))}
          </select>
          {/* <DetalleTemplate selected={this.props.tarea.template_failed_task.idTemplate} /> */}
        </div>

        {this.props.accion === "Editar" ? (
          <div className="w-100">
            {this.props.tarea.idstatus_task === ID_EXITOSO ||
            this.props.tarea.idstatus_task === ID_FALLIDO ? (
              <div className="form-row">
                <div className="form-group col-md-6">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.props.ingresarEvidencia}
                        onChange={this.props.handleChangeCheck}
                        name="ingresarEvidencia"
                      />
                    }
                    label="¿Desea ingresar evidencias?"
                  />
                </div>
                <div className="form-group col-md-6">
                  <Button
                    disabled={!this.props.ingresarEvidencia}
                    variant="contained"
                    color="primary"
                    className="d-block ml-auto w-100"
                    onClick={() => $("#modal").modal("show")}
                  >
                    Ingresar evidencias
                  </Button>
                </div>
              </div>
            ) : (
              false
            )}
          </div>
        ) : (
          false
        )}

        {this.props.tarea.type_task === 1 &&
        this.props.accion === "Crear" &&
        this.props.tipo === "0"
          ? false
          : // <label
            //     className="w-100 text-right href-style mb-0"
            //     onClick={() => this.props.agregarDelivery(this.props.tarea)}
            // >
            //     Generar Delivery
            // </label>
            false}
      </div>
    );
  }
}

export default FormularioTareas;
