import {
    OBTENER_RAYOS,
    OBTENER_ALL_RAYOS,
    UPDATE_RAYOS,
    UPDATE_STATE_RAYOS,
    CREATE_RAYOS,
    OBTENER_TAREAS_RAYO,
    ERROR_RAYOS,
    MODIFICAR_TAREAS_RAYO,
    DES_SELECCIONAR_TAREAS,
    SELECCIONAR_TODAS_TAREAS,
    VACIAR_TAREAS_RAYO,
    REORDER_TAREAS,
    REORDER_GOOGLE,
    TASKS_OPTIMIZATION,
    ADD_RAYO,
    DELETE_RAYO,
    RES_ERROR,
} from '../actions/Rayos';

const estadoInicial = {
    rayos: null,
    allRayos: null,
    tareas: null,
    error: null,
    localidades: null,
    seleccionadoT: false,
    contadorSeleccionado: 0,
    wayPoints: [],
    orderWayPoints: [],
    otherPoints: [],
};

const INITIAL_STATE = {
    loggedIn: true,
};

const rayosReducer = (estado = estadoInicial, accion) => {
    switch (accion.type) {
        case OBTENER_RAYOS:
            return {
                ...estado,
                rayos: accion.rayos,
                localidades: accion.localidades,
            };
        case ADD_RAYO:
            return {
                ...estado,
                rayos: accion.rayos,
                localidades: accion.localidades,
                allRayos: accion.allRayos,
            };
        case DELETE_RAYO:
            return {
                ...estado,
                rayos: accion.rayos,
                localidades: accion.localidades,
                allRayos: accion.allRayos,
            };
        case OBTENER_ALL_RAYOS:
            return {
                ...estado,
                rayos: accion.rayos,
                localidades: accion.localidades,
                allRayos: accion.allRayos,
            };
        case CREATE_RAYOS:
            return {
                ...estado,
                rayos: accion.rayos,
                localidades: accion.localidades,
            };
        case UPDATE_RAYOS:
            return {
                ...estado,
                rayos: accion.rayos,
            };
        case UPDATE_STATE_RAYOS:
            return {
                ...estado,
                rayos: accion.rayos,
                localidades: accion.localidades,
            };
        case OBTENER_TAREAS_RAYO:
            return {
                ...estado,
                tareas: accion.tareas,
            };
        case MODIFICAR_TAREAS_RAYO:
            return {
                ...estado,
                tareas: accion.tareas,
                seleccionadoT: accion.seleccionadoT,
                contadorSeleccionado: accion.contadorSeleccionado,
                wayPoints: accion.wayPoints,
                orderWayPoints: accion.orderWayPoints,
                otherPoints: accion.otherPoints,
            };
        case DES_SELECCIONAR_TAREAS:
            return {
                ...estado,
                tareas: accion.tareas,
                seleccionadoT: accion.seleccionadoT,
                contadorSeleccionado: accion.contadorSeleccionado,
                wayPoints: accion.wayPoints,
                orderWayPoints: accion.orderWayPoints,
                otherPoints: accion.otherPoints,
            };
        case SELECCIONAR_TODAS_TAREAS:
            return {
                ...estado,
                tareas: accion.tareas,
                seleccionadoT: accion.seleccionadoT,
                contadorSeleccionado: accion.contadorSeleccionado,
                wayPoints: accion.wayPoints,
                orderWayPoints: accion.orderWayPoints,
                otherPoints: accion.otherPoints,
            };
        case VACIAR_TAREAS_RAYO:
            return {
                ...estado,
                tareas: accion.tareas,
            };
        case REORDER_TAREAS:
            return {
                ...estado,
                tareas: accion.tareas,
                wayPoints: accion.wayPoints,
                orderWayPoints: accion.orderWayPoints,
                otherPoints: accion.otherPoints,
            };
        case REORDER_GOOGLE:
            return {
                ...estado,
                tareas: accion.tareas,
                wayPoints: accion.wayPoints,
            };
        case TASKS_OPTIMIZATION:
            return {
                ...estado,
                tareas: accion.tareas,
                wayPoints: accion.wayPoints,
            };
        case ERROR_RAYOS:
            return {
                ...estado,
                error: accion.error,
            };
        case RES_ERROR:
            return INITIAL_STATE;
        default:
            return estado;
    }
};

export default rayosReducer;
