import React from 'react';

import { LIGHT_MODE_GOOGLE } from '../../utils/Helpers';
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps';

import IconoCerrar from '../../icons/icono-cerrar-grey.svg';

const { MarkerClusterer } = require('react-google-maps/lib/components/addons/MarkerClusterer');
const { InfoBox } = require('react-google-maps/lib/components/addons/InfoBox');

const WrappedMap0 = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            onTilesLoaded={() => {}}
            defaultZoom={10}
            zoom={props.zoom}
            defaultCenter={{ lat: -33.4473526, lng: -70.6379711 }}
            options={{
                styles: LIGHT_MODE_GOOGLE,
            }}
            center={props.center}
        >
            <MarkerClusterer onClick={props._handleClickCluster} averageCenter enableRetinaIcons gridSize={30}>
            {props.positions.map((position, i) => {
                return (
                    props.isMarkerShown && (
                        <Marker
                            onClick={() => props._handleClickMarker(position.placeName)}
                            icon={position.icon}
                            key={position.task}
                            title={position.placeName}
                            position={{
                                lat: position.position.lat,
                                lng: position.position.lng,
                            }}
                        ></Marker>
                    )
                );
            })}
            </MarkerClusterer>
            {props.rayos.map((rayo, index) => {
                return (
                    props.isMarkerShown && (
                        <Marker
                            onClick={() => props.openInfoBox(rayo)}
                            icon={rayo.icon}
                            key={rayo.dataRayo.id + index}
                            position={{
                                lat: rayo.position.lat,
                                lng: rayo.position.lng,
                            }}
                        ></Marker>
                    )
                );
            })}

            {props.abrirInfoBox && props.infoBoxInfo != null ? (
                <InfoBox
                    position={
                        new window.google.maps.LatLng(props.infoBoxInfo.position.lat, props.infoBoxInfo.position.lng)
                    }
                    options={{
                        closeBoxURL: ``,
                        enableEventPropagation: true,
                        pixelOffset: new window.google.maps.Size(0, -105),
                    }}
                >
                    <div id="content-mapa">
                        <img
                            src={IconoCerrar}
                            height="12"
                            className="cerrar"
                            alt="cerrar"
                            onClick={() => props.openInfoBox(null)}
                        />

                        <div className="row m-0">
                            <div className="d-inline d-flex align-items-center mb-1">
                                <div className="m-0 dot activo"></div>
                            </div>
                            <label className="text-exo-bold mb-1 pl-2">{props.infoBoxInfo.dataRayo.name}</label>
                        </div>
                        <div id="bodyContent">
                            <p className="text-roboto-regular mb-0">{'ID: ' + props.infoBoxInfo.dataRayo.id}</p>
                            <p className="text-roboto-regular mb-0">{'DNI: ' + props.infoBoxInfo.dataRayo.dni}</p>
                            <p className="text-roboto-regular mb-0">
                                {'Número de contacto: ' + props.infoBoxInfo.dataRayo.phone}
                            </p>
                            <p className="text-roboto-regular mb-0">{'Email: ' + props.infoBoxInfo.dataRayo.email}</p>
                        </div>
                    </div>
                </InfoBox>
            ) : (
                false
            )}
        </GoogleMap>
    ))
);

const WrappedMap1 = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            onTilesLoaded={() => {}}
            defaultZoom={10}
            zoom={props.zoom}
            defaultCenter={{ lat: -33.4473526, lng: -70.6379711 }}
            options={{
                styles: LIGHT_MODE_GOOGLE,
            }}
            center={props.center}
        >
            <MarkerClusterer onClick={props._handleClickCluster} averageCenter enableRetinaIcons gridSize={30}>
            {props.positions.map((position, i) => {
                return (
                    props.isMarkerShown && (
                        <Marker
                            onClick={() => props._handleClickMarker(position.placeName)}
                            icon={position.icon}
                            key={position.task}
                            title={position.placeName}
                            position={{
                                lat: position.position.lat,
                                lng: position.position.lng,
                            }}
                        ></Marker>
                    )
                );
            })}
            </MarkerClusterer>
            {props.rayos.map((rayo, index) => {
                return (
                    props.isMarkerShown && (
                        <Marker
                            onClick={() => props.openInfoBox(rayo)}
                            icon={rayo.icon}
                            key={rayo.dataRayo.id + index}
                            position={{
                                lat: rayo.position.lat,
                                lng: rayo.position.lng,
                            }}
                        ></Marker>
                    )
                );
            })}

            {props.abrirInfoBox && props.infoBoxInfo != null ? (
                <InfoBox
                    defaultPosition={
                        new window.google.maps.LatLng(props.infoBoxInfo.position.lat, props.infoBoxInfo.position.lng)
                    }
                    options={{
                        closeBoxURL: ``,
                        enableEventPropagation: true,
                        pixelOffset: new window.google.maps.Size(0, -105),
                    }}
                >
                    <div id="content-mapa">
                        <img
                            src={IconoCerrar}
                            height="12"
                            className="cerrar"
                            alt="cerrar"
                            onClick={() => props.openInfoBox(null)}
                        />

                        <div className="row m-0">
                            <div className="d-inline d-flex align-items-center mb-1">
                                <div className="m-0 dot activo"></div>
                            </div>
                            <label className="text-exo-bold mb-1 pl-2">{props.infoBoxInfo.dataRayo.name}</label>
                        </div>
                        <div id="bodyContent">
                            <p className="text-roboto-regular mb-0">{'ID: ' + props.infoBoxInfo.dataRayo.id}</p>
                            <p className="text-roboto-regular mb-0">{'DNI: ' + props.infoBoxInfo.dataRayo.dni}</p>
                            <p className="text-roboto-regular mb-0">
                                {'Número de contacto: ' + props.infoBoxInfo.dataRayo.phone}
                            </p>
                            <p className="text-roboto-regular mb-0">{'Email: ' + props.infoBoxInfo.dataRayo.email}</p>
                        </div>
                    </div>
                </InfoBox>
            ) : (
                false
            )}
        </GoogleMap>
    ))
);

const WrappedMapDetalle = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            defaultZoom={12}
            options={{
                styles: LIGHT_MODE_GOOGLE,
            }}
            center={
                props.detalle && props.positions
                    ? props.positions.lenght === 0
                        ? { lat: -33.4473526, lng: -70.6379711 }
                        : { lat: props.positions[0].position.lat, lng: props.positions[0].position.lng }
                    : { lat: -33.4473526, lng: -70.6379711 }
            }
        >
            {/* <MarkerClusterer onClick={props._handleClickCluster} averageCenter enableRetinaIcons gridSize={40}> */}
            {props.positions.map((position, i) => {
                return (
                    props.isMarkerShown && (
                        <Marker
                            onClick={() => props._handleClickMarker(position.placeName)}
                            icon={position.icon}
                            key={position.task}
                            title={position.placeName}
                            position={{
                                lat: position.position.lat,
                                lng: position.position.lng,
                            }}
                        ></Marker>
                    )
                );
            })}
            {/* </MarkerClusterer> */}
        </GoogleMap>
    ))
);

export { WrappedMap0, WrappedMap1, WrappedMapDetalle };
