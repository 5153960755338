import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import "../../css/global.css";
import Utils from "../../assets/utils/Utils";
import url from "../../config";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@material-ui/core";

import IconoCerrar from "../../assets/icons/icono-cerrar-white.svg";
import TextFieldOutlined from "../../assets/components/custom/TextFieldOutlined";

import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Skeleton from "@material-ui/lab/Skeleton";

const columns = [
  { id: "name_commune", label: "Nombre", align: "left" },
  { id: "id_city", label: "Ciudad", align: "left" },
  { id: "accion", label: "Acción", align: "right" },
];

class Comunas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,

      search: "",
      communes: null,
      cities: [],
      form: {
        key: "",
        name_commune: "",
        id_city: "",
        comuna: "",
      },
    };
    this.getCommunes();
    this.getCities();
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  emptyForm = () => {
    this.setState({
      form: {
        key: "",
        name_commune: "",
        id_city: "",
        comuna: "",
      },
    });
  };

  getCities = async () => {
    try {
      let data = await axios({
        method: "get",
        url: url.host + "/Cities/cities/",
      }).then(({ data }) => data);
      this.setState({ cities: data });
    } catch (err) {
      Utils.handleAlert(
        "Obtener ciudades",
        "No se han podido cargar las ciudades",
        "Ok"
      );
    }
  };

  getCommunes = async () => {
    try {
      let data = await axios({
        method: "get",
        url: url.host + "/Communes/communes/",
      }).then(({ data }) => data);
      this.setState({ communes: data });
    } catch (err) {
      Utils.handleAlert(
        "Obtener comunas",
        "No se han podido cargar las comunas",
        "Ok"
      );
    }
  };

  createCommune = async () => {
    try {
      let res = await url.api.post("/Communes/commune/", {
        name_commune: this.state.form.name_commune,
        id_city: this.state.form.id_city,
      });
      if (res.status === 201 || res.status === 200) {
        $("#crearComunaModal").modal("hide");
        this.getCommunes();
        Utils.handleAlert(
          "Comuna ingresada",
          "La comuna se ha ingresado exitosamente",
          "Ok"
        );
        this.emptyForm();
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al ingresar la comuna, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al ingresar la comuna, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  confirmDelete = (key) => {
    Swal.fire({
      title: "¿Eliminar Comuna?",
      text:
        "Si confirma, no podrá deshacer la acción y la comuna será eliminada de forma permanente",
      imageUrl:
        "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/logo_clubbees.png",
      showCancelButton: true,
      customClass: {
        image: "imageSweetAlert",
        title: "titleSweetAlert",
        content: "textSweetAlert",
        confirmButton: "buttonSweetAlert",
        cancelButton: "btn-secondary",
      },
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.deleteCommune(key);
      }
    });
  };

  deleteCommune = async (key) => {
    try {
      const commune = {
        key: key,
      };
      let res = await url.api.delete("/Communes/commune/", {
        data: commune,
        headers: { Authorization: "***" },
      });
      if (res.status === 200) {
        this.getCommunes();
        Utils.handleAlert(
          "Comuna eliminada",
          "La comuna se ha eliminado exitosamente",
          "Ok"
        );
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al eliminar la comuna, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al eliminar la comuna, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  modifyCommune = async () => {
    try {
      let res = await url.api.put("/Communes/commune/", {
        key: this.state.form.key,
        name_commune: this.state.form.name_commune,
        id_city: this.state.form.id_city,
      });
      if (res.status === 200) {
        this.getCommunes();
        $("#modificarComunaModal").modal("hide");
        Utils.handleAlert(
          "Comuna modificada",
          "La comuna se ha modifico exitosamente",
          "Ok"
        );
        this.emptyForm();
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al modificar la comuna, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al modificar la comuna, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  editar = async (comuna) => {
    await this.setState({
      form: {
        ...this.state.form,
        key: comuna.id,
        name_commune: comuna.name_commune,
        id_city: comuna.id_city,
      },
    });
    $("#modificarComunaModal").modal("show");
  };

  render() {
    const { page, rowsPerPage } = this.state;

    const Cities = ({ cities }) => {
      return (
        <select
          required
          className="custom-select"
          name="id_city"
          onChange={this.handleChange}
          value={this.state.form.id_city}
        >
          <option value="" disabled>
            - Seleccionar Ciudad -
          </option>
          {cities.map(({ id, name_city }, index) => (
            <option value={id} key={index}>
              {name_city}
            </option>
          ))}
        </select>
      );
    };

    const Ciudad = ({ id }) => {
      return this.state.cities
        .filter((city) => {
          if (city.id === id) {
            return true;
          } else {
            return false;
          }
        })
        .map((city, index) => {
          return "" + city.name_city;
        });
    };

    return (
      <Box pt={4}>
        <Container>
          <Typography variant="h1" gutterBottom>
            Comunas
          </Typography>

          <Box width="100%" display="flex" mb={3}>
            <Box flexGrow="1">
              <TextFieldOutlined
                size="small"
                name="search"
                value={this.state.search}
                onChange={(e) => {
                  if (this.state.page !== 0) this.setState({ page: 0 });
                  this.setState({ search: e.target.value });
                }}
                variant="outlined"
                placeholder="Buscar..."
                style={{ minWidth: 250 }}
              />
            </Box>
            <Button
              color="secondary"
              variant="contained"
              data-toggle="modal"
              data-target="#crearComunaModal"
            >
              Crear nuevo
            </Button>
          </Box>

          <TableContainer className="table-white">
            <Table aria-label="Comunas">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell align={column.align} key={index}>
                      <b>{column.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.communes ? (
                  this.state.communes.length === 0 ? (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={columns.length} align="center">
                        <em>- No existen comunas creadas -</em>
                      </TableCell>
                    </TableRow>
                  ) : (
                    this.state.communes
                      .filter((commune) => {
                        let bandera = false;
                        if (
                          commune.name_commune
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          commune._id
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase())
                        ) {
                          bandera = true;
                        }
                        return bandera;
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => (
                        <TableRow hover key={i}>
                          <TableCell align="left">{row.name_commune}</TableCell>
                          <TableCell align="left">
                            <Ciudad id={row.id_city} />
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label="update"
                              onClick={() => this.editar(row)}
                            >
                              <CreateIcon color="primary" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => this.confirmDelete(row._id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  )
                ) : (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    {columns.map((e, i) => (
                      <TableCell key={i}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="w-100 mb-4">
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todas" }]}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count, page }) => {
                return from + " - " + to + " de " + count + " comunas";
              }}
              component="div"
              count={
                this.state.communes
                  ? this.state.communes.filter((commune) => {
                      let bandera = false;
                      if (
                        commune.name_commune
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()) ||
                        commune._id
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase())
                      ) {
                        bandera = true;
                      }
                      return bandera;
                    }).length
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>

          <div
            className="modal fade"
            id="crearComunaModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="crearModalLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4
                    className="modal-title title-text-white ml-3"
                    id="crearModalLabel"
                  >
                    Crear Comuna
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => {
                      $("#crearModalLabel").modal("hide");
                      this.emptyForm();
                    }}
                    aria-label="Close"
                  >
                    <span aria-hidden="true" className="title-text-white">
                      <img src={IconoCerrar} height="37" alt="Logo Rayo" />
                    </span>
                  </button>
                </div>
                <form onSubmit={this.validarCrear}>
                  <div className="modal-body">
                    <div className="form-group">
                      <label className="texto-form mb-1">
                        Nombre de la comuna
                      </label>
                      <label className="texto-form-red-light"> *</label>
                      <input
                        required
                        onChange={this.handleChange}
                        type="text"
                        name="name_commune"
                        className="form-control"
                        placeholder="Nombre"
                        value={this.state.form.name_commune}
                      />
                    </div>
                    <div className="form-group">
                      <label className="texto-form mb-1">Ciudad</label>
                      <label className="texto-form-red-light"> *</label>
                      <Cities cities={this.state.cities} />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button type="submit" variant="contained" color="secondary">
                    Crear
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="modificarComunaModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="editarModalLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4
                    className="modal-title title-text-white ml-3"
                    id="exampleModalLabel"
                  >
                    Modificar Comuna
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => {
                      $("#editarModalLabel").modal("hide");
                      this.emptyForm();
                    }}
                    aria-label="Close"
                  >
                    <span aria-hidden="true" className="title-text-white">
                      <img src={IconoCerrar} height="37" alt="Logo Rayo" />
                    </span>
                  </button>
                </div>
                <form onSubmit={this.validarEditar}>
                  <div className="modal-body">
                    <div className="form-group">
                      <label className="texto-form mb-1">
                        Nombre de la comuna
                      </label>
                      <label className="texto-form-red-light"> *</label>
                      <input
                        required
                        onChange={this.handleChange}
                        type="text"
                        name="name_commune"
                        className="form-control"
                        placeholder="Nombre"
                        value={this.state.form.name_commune}
                      />
                    </div>
                    <div className="form-group">
                      <label className="texto-form mb-1">Ciudad</label>
                      <label className="texto-form-red-light"> *</label>
                      <Cities cities={this.state.cities} />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button type="submit" variant="contained" color="secondary">
                    Modificar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </Box>
    );
  }

  validar = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  validarCrear = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
    } else {
      this.validar(event);
      this.createCommune();
    }
  };

  validarEditar = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
    } else {
      this.validar(event);
      this.modifyCommune();
    }
  };
}

export default Comunas;
