import React from "react";

import IconoFlechaBlanca from "../../../assets/icons/icono-up-white.png";
import IconoRuta from "../../../assets/icons/icono-map-white.svg";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

class BotonesTareasSeleccionadas extends React.Component {
  render() {
    return (
      <div className="bottom-fix position-absolute bg-light">
        <div className="row m-0">
          {this.props.vista === "backlog" ? (
            <div className="col-6 pr-2">
              <Button
                variant="contained"
                color="primary"
                className="text-capitalize py-1 w-100"
                onClick={() =>
                  this.props.handleAsignarRayo(
                    this.props.rayos,
                    this.props.backlog
                  )
                }
                startIcon={
                  <img
                    height="20"
                    src={IconoFlechaBlanca}
                    alt="Icono tarea"
                    className="mb-1"
                  />
                }
              >
                Asignar Rayo
              </Button>
            </div>
          ) : (
            <div className={"col-12 px-2"}>
              <Button
                variant="contained"
                color="primary"
                className="text-capitalize py-1 w-100"
                onClick={() =>
                  this.props.handleAsignarRayo(
                    this.props.rayos,
                    this.props.backlog
                  )
                }
                startIcon={
                  <img
                    height="20"
                    src={IconoFlechaBlanca}
                    alt="Icono tarea"
                    className="mb-1"
                  />
                }
              >
                Asignar Rayo
              </Button>
            </div>
          )}
          {this.props.vista === "backlog" ? (
            <div className="col-6 pl-2 d-flex justify-content-end">
              <Tooltip
                arrow
                title="Para forzar el término de una ruta en un lugar deseado, arrastra la tarea con la que deseas finalizar a la última posición de la lista"
                placement="top"
              >
                <Button
                  onClick={() => this.props.handlePanels("optimizar")}
                  variant="contained"
                  color={this.props.optimizar ? "secondary" : "primary"}
                  className="text-capitalize py-1 w-100"
                  startIcon={
                    <img
                      height="20"
                      src={IconoRuta}
                      alt="Icono ruta"
                      className="mb-1"
                    />
                  }
                >
                  Optimizar Ruta
                </Button>
              </Tooltip>
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    );
  }
}

export default BotonesTareasSeleccionadas;
