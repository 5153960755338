import { createTheme } from "@material-ui/core/styles";
// import config from '../../theme';

const theme = createTheme();

// ------- PALETTE -------
theme.palette.primary = {
  light: "#5240f2",
  main: "#311DEF",
  dark: "#1f0dbf",
  contrastText: "#fff",
};

theme.palette.secondary = {
  light: "#5fcbec",
  main: "#1CB5E5",
  dark:"#137fa0",
  contrastText: "#fff",
};

theme.palette.success = {
  light: "#e9eefb",
  main: "#e4e9fa",
  dark:"#9999ff",
  contrastText: "#000",
};

theme.palette.error = {
  light: "#ff8566",
  main: "#ff471a",
  dark:"#cc2900",
  contrastText: "#fff",
}

// ------- TYPOGRAPHY -------
theme.typography.h1 = { fontFamily: "Exo2-Bold" };

theme.typography.h2 = { fontFamily: "Exo2-Bold" };

theme.typography.h3 = { fontFamily: "Exo2-Bold" };

theme.typography.h4 = { fontFamily: "Exo2-Bold" };

theme.typography.h5 = {
  fontFamily: "Exo2-Bold",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
};

theme.typography.body1 = {
  fontFamily: "Roboto-Regular",
  fontSize: ".85rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.7rem",
  },
};

theme.typography.body2 = {
  fontFamily: "Roboto-Regular",
  fontSize: "0.85rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.7rem",
  },
};

theme.typography.button = { fontFamily: "Exo2-Bold, FontAwesome !important" };

export default theme;
